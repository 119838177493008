import { UserFromAccessToken } from "~/apps/corporate/models/user.model";
import jwtDecode from "jwt-decode";

import { buildDate } from "../../../../package.json";
import { GetUserFromAccessTokenDto } from "../dtos/user.dto";

export const clearUserFromLocalStorage = () => {
  localStorage.removeItem("access_token");
};

const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const getAuthorizationHeader = () => {
  return {
    Authorization: getAccessToken(),
    ["X-Client-Version"]: buildDate,
  };
};

export const getClientToken = () => {
  const user = getUserFromLocalStorage();

  return user ? user.clientToken : undefined;
};

export const getUserFromAccessToken = (accessToken: string) => {
  const jwtData = jwtDecode<GetUserFromAccessTokenDto>(accessToken);

  const userFromAccessToken: UserFromAccessToken = {
    agencyToken: jwtData.agency_token,
    capabilities: jwtData.capabilities,
    clientName: jwtData.client_name,
    clientToken: jwtData.client_token,
    guest: jwtData.guest,
    hidden: jwtData.hidden,
    permissions: jwtData.permissions,
    plan: jwtData.plan,
    role: jwtData.role,
    sendSms: jwtData.send_sms,
    userToken: jwtData.user_token,
    vipStatus: jwtData.vip_status,
  };

  return userFromAccessToken;
};

export const getUserFromLocalStorage = () => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return null;
  }

  return getUserFromAccessToken(accessToken);
};

import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import { FlightFactory } from "~/apps/corporate/parsers/travel.parser";
import { ERROR } from "~/apps/shared/constants/errors";
import { api } from "~/apps/shared/services/api";
import {
  ICreateFlightChangeSessionReqDto,
  IRequestFlightChangeReqDto,
  ISelectFlightChangeOptionsReqDto,
} from "sm-types/api-core/flight-controller/requests";
import {
  IGetFlightChangeSummaryResDto,
  IRequestFlightChangeResDto,
  ISelectFlightChangeOptionsResDto,
  RecalculateFlightOfferData,
} from "sm-types/api-core/flight-controller/responses";

import {
  GetUserRecentFlightsResponse,
  AddFlightOfferRequest,
  AddFlightOfferResponse,
  CheckReservationConflictResponse,
  CheckReservationConflictRequestBody,
  CheckPlaneClientLimitRequestBody,
  CheckPlaneClientLimitResponse,
  GetFlightResponse,
  GetFlightChangeListResponse,
  CreateFlightChangeSessionResponse,
  GetPaymentMethodForOfferChangeDto,
  CheckUserPayForTravelChangeDto,
} from "../dtos/flights.dto";
import * as flightParser from "../parsers/flight.parser";

export async function getFlight(flightToken: string) {
  return await api
    .request<GetFlightResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/flight/${flightToken}`,
    })
    .then(({ data }) => data);
}

export async function addFlightOffer(data: AddFlightOfferRequest) {
  return await api
    .request<AddFlightOfferResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/offers/flight`,
    })
    .then(({ data }) => data);
}

export async function aiportAutosuggest(search: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      timeout: 25000,
      url: `/booking/autosuggest/flight/${search}`,
    })
    .then(({ data }) => {
      return data.location_list.map((item: any) => {
        const label = `(${item.PlaceId.split("-")[0]}) ${item.PlaceName} - ${
          item.CountryId.split("-")[0]
        }`;

        return {
          ...item,
          label,
        };
      });
    });
}

export async function cancelPreReserve(data: { offerToken: string }) {
  return await api
    .request<any>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/flight/cancel-pre-reserve",
    })
    .then(({ data }) => data);
}

export async function checkReservationConflict(
  data: CheckReservationConflictRequestBody,
) {
  return await api
    .request<CheckReservationConflictResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/flight/check-reservation-conflict",
    })
    .then(({ data }) => flightParser.parseCheckReservationConflict(data));
}

export async function checkPlaneClientLimit(
  data: CheckPlaneClientLimitRequestBody,
) {
  return await api
    .request<CheckPlaneClientLimitResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/flight/check-plane-limit",
    })
    .then(({ data }) => data);
}

export async function createFlightsSearch(data: any) {
  return await api
    .request<any>({
      data: data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/flight`,
    })
    .then(({ data }) => FlightFactory(data));
}

function getCarriersFromFlight(flight: any) {
  const carriers: any = {};

  if (flight.inbound && flight.inbound.carrier) {
    carriers[flight.inbound.carrier.code] = true;
  }
  if (flight.outbound && flight.outbound.carrier) {
    carriers[flight.outbound.carrier.code] = true;
  }

  return carriers;
}

export async function getFlights(flightToken: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      timeout: 1000 * 60 * 3,
      url: `/booking/flight/${flightToken}/list`,
    })
    .then(({ data }) => {
      const availableCarriers = data.availableCarriers;

      return {
        availableCarriers: Object.keys(availableCarriers)
          .map((key) => availableCarriers[key])
          .sort((a, b) => a.name.localeCompare(b.name)),
        countryRestriction: data.countryRestriction,
        flightInfo: data.flightInfo,
        flights: data.flightList.map((flight: any) => {
          return { ...flight, carriers: getCarriersFromFlight(flight) };
        }),
        generalInfo: data.generalInfo,
        policy: data.policy,
        travelerInfo: data.travelerInfo,
        travelInfo: data.travelInfo,
        validUntil: data.searchValidUntil,
      };
    })
    .catch(handleGetFlightsError);
}

export async function getUserRecentFlights(userToken: string) {
  return await api
    .request<GetUserRecentFlightsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/users/${userToken}/flights/recent`,
    })
    .then(({ data }) => flightParser.parseUserRecentFlights(data));
}

function handleGetFlightsError(err: any) {
  if (err.code === "ECONNABORTED") {
    throw ERROR.TIMEOUT_FLIGHT_LIST_ERROR;
  } else {
    throw ERROR.UNEXPECTED_FLIGHT_LIST_ERROR;
  }
}

export function listFlightFareFamilies(
  flightToken: string,
  requestDto: { search_id: string; itinerary_id: string },
) {
  return api
    .request({
      url: `/booking/flight/${flightToken}/fare-families`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestDto,
    })
    .then((response) => response.data);
}

export async function getFlightChangeList(
  offerToken: string,
  sessionId: string,
): Promise<GetFlightChangeListResponse> {
  return await api
    .request<GetFlightChangeListResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/offers/${offerToken}/flight-change/search/${sessionId}`,
    })
    .then(({ data }) => data);
}

export async function createFlightChangeSession(
  offerToken: string,
  requestDto: ICreateFlightChangeSessionReqDto,
) {
  return await api
    .request<CreateFlightChangeSessionResponse>({
      url: `/booking/offers/${offerToken}/flight-change/search`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestDto,
    })
    .then(({ data }) => data);
}

export async function selectFlightChangeOptions(
  offerToken: string,
  sessionId: string,
  requestDto: ISelectFlightChangeOptionsReqDto,
) {
  return await api
    .request<ISelectFlightChangeOptionsResDto>({
      url: `/booking/offers/${offerToken}/flight-change/select/${sessionId}`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestDto,
    })
    .then(({ data }) => data);
}

export async function getFlightChangeSummary(
  offerToken: string,
  sessionId: string,
): Promise<IGetFlightChangeSummaryResDto> {
  return await api
    .request<IGetFlightChangeSummaryResDto>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/offers/${offerToken}/flight-change/summary/${sessionId}`,
    })
    .then(({ data }) => data);
}

export async function recalculateFlightOffer(
  offerToken: string,
  sessionId: string,
) {
  return await api
    .request<RecalculateFlightOfferData>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/offers/${offerToken}/flight-change/recalculate/${sessionId}`,
    })
    .then(({ data }) => data);
}

export async function requestFlightChange(
  sessionId: string,
  requestDto: IRequestFlightChangeReqDto,
) {
  return await api
    .request<IRequestFlightChangeResDto>({
      url: `/booking/solicitations/request-flight-change/${sessionId}`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestDto,
    })
    .then(({ data }) => data);
}

export async function getPaymentMethodForOfferChange(
  offerToken: string,
  userToken: string,
) {
  return await api
    .request<GetPaymentMethodForOfferChangeDto>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/offers/${offerToken}/users/${userToken}/offer-change-payment-method`,
    })
    .then(({ data }) => data);
}

export async function checkUserPayForTravelChange(
  travelToken: string,
  userToken: string,
) {
  return await api
    .request<CheckUserPayForTravelChangeDto>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/users/${userToken}/check-payment-for-change`,
    })
    .then(({ data }) => data);
}

import React, { createContext, useCallback, useState } from "react";

import { ALERT_TYPES } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";

import { SaveLoyaltyProgramDto } from "../dtos/loyalty-program.dto";
import { LoyaltyProgram } from "../models/loyalty-program.model";
import { useApplication } from "./application.context";
import * as loyaltyProgramService from "./loyalty-program.service";
import { useUser } from "./user.context";

interface Actions {
  fetchUserFidelityPrograms: () => Promise<void>;
  removeLoyaltyProgram: (program: LoyaltyProgram) => Promise<void>;
  saveLoyaltyProgram: (data: SaveLoyaltyProgramDto) => Promise<boolean>;
}

type State = {
  errorOnFetchRemoveLoyaltyProgram: Error | null;
  isLoading: boolean;
  isLoadingRemoveLoyaltyProgram: boolean;
  isLoadingSaveLoyaltyProgram: boolean;
  programs: LoyaltyProgram[];
};

const initialState: State = {
  errorOnFetchRemoveLoyaltyProgram: null,
  isLoading: false,
  isLoadingRemoveLoyaltyProgram: false,
  isLoadingSaveLoyaltyProgram: false,
  programs: [],
};

type ContextProps = Actions & State;

const LoyaltyProgramsContext = createContext<ContextProps>({
  ...initialState,
  fetchUserFidelityPrograms: async () => {
    return;
  },
  removeLoyaltyProgram: async () => {
    return;
  },
  saveLoyaltyProgram: async () => {
    return false;
  },
});

export const LoyaltyProgramsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useState(initialState);

  const fetchUserFidelityPrograms = useCallback(async () => {
    if (!user) {
      return;
    }

    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const userLoyaltyProgramsResponse = await loyaltyProgramService.getUserLoyaltyPrograms(
      user.getUserToken(),
    );

    if (userLoyaltyProgramsResponse.error) {
      const error = userLoyaltyProgramsResponse.error;

      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
      programs: userLoyaltyProgramsResponse.data!,
    }));
  }, [showSnackMessage, user]);

  const removeLoyaltyProgram = useCallback(
    async (program: LoyaltyProgram) => {
      setState((prev) => ({
        ...prev,
        errorOnFetchRemoveLoyaltyProgram: null,
        isLoadingRemoveLoyaltyProgram: true,
      }));

      const inactiveLoyaltyProgramResponse = await loyaltyProgramService.inactiveLoyaltyProgram(
        program.loyaltyProgramToken,
      );

      if (inactiveLoyaltyProgramResponse.error) {
        const error = inactiveLoyaltyProgramResponse.error;

        setState((prev) => ({
          ...prev,
          errorOnFetchRemoveLoyaltyProgram: error,
          isLoadingRemoveLoyaltyProgram: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      setState((prev) => ({
        ...prev,
        isLoadingRemoveLoyaltyProgram: false,
        programs: prev.programs.filter(
          (existingProgram) =>
            existingProgram.loyaltyProgramToken !== program.loyaltyProgramToken,
        ),
      }));
    },
    [showSnackMessage],
  );

  const saveLoyaltyProgram = useCallback(
    async (data: SaveLoyaltyProgramDto) => {
      const { programs } = state;

      const loyaltyProgramAlreadyExists =
        !data.token &&
        programs.some((loyaltyProgram) => loyaltyProgram.name === data.name);

      if (loyaltyProgramAlreadyExists) {
        showSnackMessage(
          "Já existe um código cadastrado para esse programa.",
          ALERT_TYPES.ERROR,
        );

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingSaveLoyaltyProgram: true,
      }));

      const saveLoyaltyProgramResponse = !data.token
        ? await loyaltyProgramService.createLoyaltyProgram(data)
        : await loyaltyProgramService.editLoyaltyProgram(data, data.token);

      if (saveLoyaltyProgramResponse.error) {
        const error = saveLoyaltyProgramResponse.error;

        setState((prev) => ({
          ...prev,
          isLoadingSaveLoyaltyProgram: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      if (!saveLoyaltyProgramResponse.data) {
        const error = ERROR.UNEXPECTED;

        setState((prev) => ({
          ...prev,
          isLoadingSaveLoyaltyProgram: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      const savedLoyaltyProgram = saveLoyaltyProgramResponse.data!;

      setState((prev) => ({
        ...prev,
        isLoadingSaveLoyaltyProgram: false,
        programs: data.token
          ? prev.programs.map((program) =>
              program.loyaltyProgramToken === data.token
                ? savedLoyaltyProgram
                : program,
            )
          : [...prev.programs, savedLoyaltyProgram],
      }));

      return true;
    },
    [showSnackMessage, state.programs],
  );

  return (
    <LoyaltyProgramsContext.Provider
      value={{
        ...state,
        fetchUserFidelityPrograms,
        removeLoyaltyProgram,
        saveLoyaltyProgram,
      }}
    >
      {children}
    </LoyaltyProgramsContext.Provider>
  );
};

export const useLoyaltyPrograms = useContextFactory(
  "LoyaltyProgramsContext",
  LoyaltyProgramsContext,
);

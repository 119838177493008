import React, { useEffect, useMemo, useRef, useState } from "react";

import { useLivechat } from "~/apps/corporate/contexts/livechat.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ChatConfig } from "~/apps/corporate/models/agency.model";
import { UserModel } from "~/apps/corporate/models/user.model";
import { VIP_STATUS_TRANSLTATED } from "~/apps/shared/constants";

import { Icon } from "../../icon/icon";
import { styles } from "./styles";

const buildUserData = (user: UserModel) => {
  const clientName = user.getClientName();
  const clientToken = user.getClientToken();
  const plan = user.getPlan();
  const userToken = user.getUserToken();
  const vipStatus = user.getUserVipStatus();

  const translatedVipStatus = vipStatus
    ? VIP_STATUS_TRANSLTATED[vipStatus]
    : undefined;

  const dataArray = [];

  dataArray.push({ title: "ID do usuário", content: userToken });
  dataArray.push({ title: "ID do cliente", content: clientToken });
  dataArray.push({ title: "Nome do cliente", content: clientName });
  dataArray.push({ title: "Plano", content: plan });
  dataArray.push({
    title: "Prioridade de atendimento",
    content: translatedVipStatus,
  });

  return dataArray;
};

type Props = {
  chatConfig: ChatConfig;
};

export const LivechatJivoChat: React.FC<Props> = ({ chatConfig }) => {
  const { isLoaded, setIsLoaded } = useLivechat();
  const { user } = useUser();

  const [isChatButtonVisible, setChatButtonVisibility] = useState(true);

  const apiRef = useRef<any | null>(window.jivo_api || null);
  const jivoRef = useRef<HTMLElement | null>(
    document.getElementById("jcont") || null,
  );

  const isLoggedIn = useMemo(() => !!user, [user]);

  const chatUserLogin = () => {
    void setUserInfo();

    if (!jivoRef.current) {
      return;
    }

    jivoRef.current.style.visibility = "hidden";
  };

  const chatUserLogout = () => {
    if (!jivoRef.current) {
      return;
    }

    jivoRef.current.style.visibility = "hidden";
  };

  const handleOpenChat = () => {
    if (!isChatButtonVisible) {
      return;
    }

    apiRef.current?.open();
  };

  const setUserInfo = async () => {
    if (!user) {
      return;
    }

    const email = user.getEmail();
    const phone = user.getPhone();
    const fullName = user.getFullName();
    const userToken = user.getUserToken();

    const chatApi = apiRef.current || window.jivo_api;

    if (!chatApi) {
      return;
    }

    chatApi.setContactInfo({
      email,
      name: fullName,
      phone,
    });

    const userData = buildUserData(user);

    chatApi.setCustomData(userData);

    await chatApi.setUserToken(userToken);
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.id = `${chatConfig.provider}-script`;
    script.async = true;
    script.src = `${chatConfig.url}/${chatConfig.identifier}`;

    // on script load
    // does not work on mobile
    window.jivo_onLoadCallback = () => {
      apiRef.current = window.jivo_api;
      jivoRef.current = document.getElementById("jcont");

      if (jivoRef.current) {
        jivoRef.current.style.visibility = "hidden";
      }

      setIsLoaded();
    };

    window.jivo_onOpen = () => {
      setChatButtonVisibility(false);

      void setUserInfo();
    };

    window.jivo_onClose = () => setChatButtonVisibility(true);

    const headEl = document.getElementsByTagName("head")[0];

    headEl.appendChild(script);
  }, [chatConfig, setIsLoaded, setUserInfo]);

  useEffect(() => {
    const isApiLoaded = isLoaded && !!apiRef.current;

    if (isApiLoaded && isLoggedIn) {
      chatUserLogin();
    }

    if (isApiLoaded && !isLoggedIn) {
      chatUserLogout();
    }
  }, [apiRef.current, isLoaded, isLoggedIn]);

  useEffect(() => {
    if (jivoRef.current && isChatButtonVisible) {
      jivoRef.current.style.visibility = "hidden";
    } else if (jivoRef.current && !isChatButtonVisible) {
      jivoRef.current.style.visibility = "visible";
    }
  }, [isChatButtonVisible, jivoRef.current]);

  const canShowButton = useMemo(
    () => isChatButtonVisible && isLoaded && !!isLoggedIn,
    [isChatButtonVisible, isLoaded, isLoggedIn],
  );

  if (!canShowButton) {
    return null;
  }

  return (
    <div css={styles.root}>
      <button css={styles.button} onClick={handleOpenChat}>
        <Icon use="chat-bubble-bottom-center-text" />
      </button>
    </div>
  );
};

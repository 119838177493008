import React, { Suspense } from "react";

import { RouteComponentProps } from "@reach/router";

import { LoadingOverlay } from "../loading-overlay/loading-overlay";

type Props = RouteComponentProps & {
  component: React.ElementType;
} & Record<string, any>;

export const LazyLoadedRoute: React.FC<Props> = ({
  component: Component,
  ...props
}) => {
  return (
    <Suspense fallback={<LoadingOverlay open />}>
      <Component {...props} />;
    </Suspense>
  );
};

import {
  BookTravelRequestBody,
  CheckPaymentPermissionResponse,
  EditAdministrativeInfoRequestBody,
  GetTravelApprovalResponse,
  GetTravelFlightCreditsResponse,
  GetTravelJustificationPolicy,
  GetTravelPaymentResponse,
  GetTravelPoliciesPerOfferResponse,
  GetTravelRemainingBudgetsResponse,
  GetUserTravelsResponse,
  UpdateTravelInfoRequestBody,
} from "~/apps/corporate/dtos/travel.dto";
import { GetItineraryResponse } from "~/apps/corporate/dtos/travel.dto";
import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import * as travelParser from "~/apps/corporate/parsers/travel.parser";
import {
  TripsStatusFilter,
  TripsTabsFilter,
} from "~/apps/shared/constants/enums";
import errorTypes, { ERROR } from "~/apps/shared/constants/errors";
import { downloadFile } from "~/apps/shared/helpers/download.helper";
import { api } from "~/apps/shared/services/api";
import { SuccessResponse } from "~/apps/shared/types";
import axios from "axios";

import * as clientApi from "./client.api";

export async function approveTravel(travelToken: string) {
  return await api
    .request<any>({
      data: {
        response_message: "",
      },
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/approval/travel/${travelToken}/approve`,
    })
    .then(({ data }) => data);
}

export async function bookTravel(
  data: BookTravelRequestBody,
  travelToken: string,
  xOrderRequestId: string,
) {
  return await api
    .request<any>({
      data,
      headers: {
        ...getAuthorizationHeader(),
        ["X-Order-Request-Id"]: xOrderRequestId,
      },
      method: "POST",
      url: `/booking/travels/${travelToken}/book`,
    })
    .then(({ data }) => data);
}

export async function checkPendingDocuments(travelToken: string) {
  return await api.request<any>({
    headers: getAuthorizationHeader(),
    method: "GET",
    url: `/booking/travels/documents/${travelToken}`,
  });
}

export async function checkPaymentPermission(
  travelToken: string,
  userToken: string,
) {
  return await api
    .request<CheckPaymentPermissionResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/users/${userToken}/payment-permission`,
    })
    .then(({ data }) => data);
}

export async function createNewTravel(data: any) {
  return await api
    .request<{
      travel_token: string;
    }>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/travels`,
    })
    .then(({ data }) => data.travel_token);
}

export async function deleteTravel(travelToken: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/travels/${travelToken}/inactivate`,
    })
    .then(() => ({
      message: "Viagem excluída com sucesso",
      success: true,
    }));
}

export async function downloadVoucher(travelToken: string) {
  return await api
    .request<any>({
      method: "GET",
      responseType: "blob",
      url: `/booking/travels/${travelToken}/download/itinerary-pdf`,
    })
    .then(({ data }) => {
      downloadFile({
        data,
        filename: `vouchers-${travelToken}-${new Date().getTime()}`,
        options: { type: "application/zip" },
      });

      return data;
    });
}

export function handleError(error: any) {
  if (error.response) {
    const { data, status } = error.response;

    if (status === 400 && data.type === errorTypes.ERROR_FINDING_TRAVEL) {
      throw { title: "title", description: "Viagem não encontrada" };
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }
  throw { title: "title", description: "Erro inesperado" };
}

export function handleLoadTravelsError(error: any) {
  if (error.response) {
    const { data, status } = error.response;

    if (status === 400 && data.type === errorTypes.ERROR_FINDING_TRAVEL) {
      throw ERROR.UNEXPECTED;
    }
  }

  throw ERROR.UNEXPECTED;
}

export async function getLoggedUserPointsInfo(userToken: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/users/${userToken}/rewards/points`,
    })
    .then(({ data }) => data);
}

export async function getTravel(travelToken: string) {
  return await getTravelItinerary(travelToken).catch(handleError);
}

export async function getTravelApproval(travelToken: string) {
  return await api
    .request<GetTravelApprovalResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/approval`,
    })
    .then(({ data }) => travelParser.parseTravelApproval(data));
}

export async function getTravelCopyStatus(copyTravelToken: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${copyTravelToken}/copy-status`,
    })
    .then(({ data }) => travelParser.TravelFactory(data.travel));
}

export async function getTravelFlightCredits(travelToken: string) {
  return await api
    .request<GetTravelFlightCreditsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/flight-credits`,
    })
    .then(({ data }) => travelParser.parseTravelFlightCredits(data));
}

export async function getTravelJustificationPolicy(
  travelToken: string,
): Promise<{
  needJustification: boolean;
  offerTokensToJustify: string[];
}> {
  return await api
    .request<GetTravelJustificationPolicy>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/justification-policy`,
    })
    .then(({ data }) => data);
}

export async function getTravelInsurance(travelToken: string, data: any) {
  return await api
    .request<any>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/travels/${travelToken}/insurance-conditions`,
    })
    .then(({ data }) => data);
}

export async function getTravelItinerary(travelToken: string) {
  return await api
    .request<GetItineraryResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/itinerary`,
    })
    .then(({ data }) => data);
}

export async function getTravelPayment(travelToken: string) {
  return await api
    .request<GetTravelPaymentResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/payment`,
    })
    .then(({ data }) => travelParser.parseTravelPayment(data));
}

export async function getTravelPoliciesPerOffer(travelToken: string) {
  return await api
    .request<GetTravelPoliciesPerOfferResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/policies`,
    })
    .then(({ data }) => data);
}

export async function getTravelRemainingBudgets(travelToken: string) {
  return await api
    .request<GetTravelRemainingBudgetsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/budgets`,
    })
    .then(({ data }) => travelParser.parseTravelRemainingBudgets(data));
}

export async function getUserPendingTravels(userToken: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/pending-approval/approvers/${userToken}`,
    })
    .then(({ data }) => data);
}

export async function getUserTravels(filters: {
  page: number;
  pendingFilters: TripsStatusFilter[];
  search: string;
  tabFilter: TripsTabsFilter;
  userToken: string;
}) {
  const { page, pendingFilters, search, tabFilter, userToken } = filters;

  const parsedPendingFilters = pendingFilters.join("&");

  const params = {
    search,
    traveler: userToken,
  };

  return await api
    .request<GetUserTravelsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      params,
      url: `/booking/travels/list/${page}/${tabFilter}/desc?${parsedPendingFilters}`,
    })
    .then(({ data }) => {
      const { travels, ...metaData } = data;
      return {
        metaData,
        travels,
      };
    })
    .catch(handleLoadTravelsError);
}

export async function getUserTravelsAndFilters(config: any) {
  return await Promise.all([clientApi.getUsers(), getUserTravels(config)])
    .then(([users, { travels, metaData }]: any) => {
      return {
        users,
        travels,
        metaData,
      };
    })
    .catch(() => {
      throw ERROR.UNEXPECTED;
    });
}

export async function sendVoucherEmail(travelToken: string) {
  return await api
    .request<SuccessResponse>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/travels/${travelToken}/vouchers/emails`,
    })
    .then(({ data }) => data);
}

export async function shareItineraryPDF(
  travelToken: string,
  blobUrl: string,
  email: string,
) {
  const pdfBlob = await axios(blobUrl, {
    method: "GET",
    responseType: "blob",
  });

  const requestData = new FormData();
  requestData.append("email", email);
  requestData.append("file", pdfBlob.data);

  return await api
    .request<any>({
      data: requestData,
      headers: {
        ...getAuthorizationHeader(),
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      url: `/booking/travels/${travelToken}/share-itinerary`,
    })
    .then(({ data }) => data);
}

export async function setTravelInsurance(travelToken: string, data: any) {
  return await api
    .request<any>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/travels/${travelToken}/insurance`,
    })
    .then(({ data }) => data);
}

export async function updateFriendHouseStatus(travelToken: string, data: any) {
  return await api.request<any>({
    data,
    headers: getAuthorizationHeader(),
    method: "POST",
    url: `/booking/travels/${travelToken}/friends-house`,
  });
}

export async function updateTravelAdministrativeInfo(
  data: EditAdministrativeInfoRequestBody,
  travelToken: string,
) {
  return await api
    .request<SuccessResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/travels/${travelToken}/change-admin-info`,
    })
    .then(({ data }) => data);
}

export async function updateTravelInfo(
  data: UpdateTravelInfoRequestBody,
  travelToken: string,
) {
  return await api
    .request<any>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/travels/${travelToken}`,
    })
    .then(({ data }) => data);
}

export async function updateTravelName(travelToken: string, name: string) {
  return await api.request<any>({
    data: {
      name,
    },
    headers: getAuthorizationHeader(),
    method: "PUT",
    url: `/booking/travels/${travelToken}/rename`,
  });
}

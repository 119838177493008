import { USER_ROLES } from "~/apps/shared/constants";
import {
  Capabilities,
  CapabilitiesLiterals,
  Permissions,
  PermissionsKeysLiterals,
  PermissionsValuesLiterals,
  UserBookingPhoneConfig,
  VipStatus,
} from "~/apps/shared/constants/enums";
import { isEveryValueTrue } from "~/apps/shared/utils/is-every-value-true";

export type User = {
  active?: boolean | number;
  agencyToken?: string;
  billingProfileToken?: string | null;
  bookingPhoneConfig?: UserBookingPhoneConfig;
  bossToken?: string | null;
  capabilities?: Partial<Capabilities>;
  clientName?: string;
  clientToken?: string;
  createdAt?: string;
  email: string;
  firstName: string;
  fullName?: string;
  guest?: boolean | null;
  hasAcceptedTerms?: boolean;
  hidden?: boolean;
  lastLogin?: string | null;
  lastName: string;
  permissions?: Partial<Permissions>;
  phone?: string | null;
  plan?: string;
  role?: string;
  sendSms?: boolean | null;
  userPreference?: {
    currency?: string;
  };
  userToken: string;
  vipStatus?: VipStatus;
};

export type UserFromAccessToken = {
  agencyToken?: string;
  capabilities?: Partial<Capabilities>;
  clientName?: string;
  clientToken?: string;
  guest?: boolean | null;
  hidden?: boolean;
  permissions?: Partial<Permissions>;
  plan?: string;
  role?: string;
  sendSms?: boolean | null;
  userToken: string;
  vipStatus?: VipStatus;
};

export class UserModel {
  constructor(private user: User) {}

  public canSearch() {
    return this.hasSearchCapability() || this.hasSelfSearchCapability();
  }

  public getAgencyToken() {
    const agencyToken = this.user.agencyToken;

    if (!agencyToken) {
      return null;
    }

    return agencyToken;
  }

  public getBillingProfileToken() {
    return this.user.billingProfileToken;
  }

  public getClientName() {
    return this.user.clientName;
  }

  public getClientToken() {
    return this.user.clientToken;
  }

  public getEmail() {
    return this.user.email;
  }

  public getFirstName() {
    return this.user.firstName;
  }

  public getFullName() {
    return this.user.fullName;
  }

  public getLastName() {
    return this.user.lastName;
  }

  public getPermissions() {
    return this.user.permissions;
  }

  public getPhone() {
    return this.user.phone;
  }

  public getPlan() {
    return this.user.plan;
  }

  public getRole() {
    return this.user.role;
  }

  public getSendSms() {
    return this.user.sendSms;
  }

  public getUserToken() {
    return this.user.userToken;
  }

  public hasApprovalsCapability() {
    return this.hasCapability("approvals");
  }

  private hasApprovalAnyTimeForOthers() {
    return this.hasCapability("approvalAnyTimeForOthers");
  }

  public hasCapability(capability: CapabilitiesLiterals) {
    const capabilities = this.user.capabilities;

    return capabilities && capability in capabilities
      ? capabilities[capability]!
      : false;
  }

  public hasConfigurationsCapability() {
    return this.hasCapability("configurations");
  }

  public hasGamificationPermission() {
    return this.hasStrictPermission("travels.gamification");
  }

  public hasLoggedIn() {
    return !!this.user.lastLogin;
  }

  public hasSearchCapability() {
    return this.hasCapability("search");
  }

  private hasSelfSearchCapability() {
    return this.hasCapability("selfSearch");
  }

  public hasStrictPermission(
    permission: PermissionsKeysLiterals,
    expected?: PermissionsValuesLiterals,
  ) {
    const value = permission
      .split(".")
      .reduce((prev, curr) => prev[curr], (this.getPermissions() || {}) as any);

    if (expected !== undefined) {
      return value === expected;
    }

    if (typeof value === "boolean") {
      return value === true;
    }

    if (typeof value === "object") {
      return isEveryValueTrue(value);
    }

    return false;
  }

  public isGuest() {
    return !!this.user.guest;
  }

  public isHidden() {
    return !!this.user.hidden;
  }

  public isSuperAdmin() {
    return this.getRole() === USER_ROLES.super;
  }

  public isMasterApprover() {
    return this.hasApprovalAnyTimeForOthers();
  }

  public toObject() {
    return this.user;
  }

  public upsertUser(user: Partial<User>) {
    this.user = {
      ...this.user,
      ...user,
    };

    return this;
  }

  public getUserVipStatus() {
    const vipStatus = this.user.vipStatus;

    if (!vipStatus) {
      return null;
    }

    return vipStatus;
  }
}

export class UserModelFactory {
  public static create(user: User) {
    return new UserModel(user);
  }
}

export type UserSearch = {
  clientToken: string;
  document: string;
  email: string;
  firstName: string;
  fullName: string;
  label: string;
  lastName: string;
  userToken: string;
};

import {
  BookTravelRequestBody,
  GetTravelApprovalResponse,
  GetTravelFlightCreditsResponse,
  GetTravelPaymentResponse,
  GetTravelRemainingBudgetsResponse,
} from "../dtos/travel.dto";
import { Budget } from "../models/budget.model";
import {
  TravelApproval,
  TravelFlightCredit,
  TravelFlightCredits,
  TravelPayment,
  TravelRemainingBudget,
} from "../models/travel.model";

export const FlightFactory = (
  dto: Partial<
    Record<
      | "advance_days"
      | "destination_city"
      | "destination_locale"
      | "destination_name"
      | "flight_token"
      | "id"
      | "inbound_flight_date"
      | "inbound_flight_end"
      | "inbound_flight_start"
      | "inbound_flight_type"
      | "international"
      | "oneway"
      | "origin_city"
      | "origin_locale"
      | "origin_name"
      | "outbound_flight_date"
      | "outbound_flight_end"
      | "outbound_flight_start"
      | "outbound_flight_type"
      | "travel_token",
      any
    >
  >,
) => {
  const {
    advance_days,
    destination_city,
    destination_locale,
    destination_name,
    flight_token,
    id,
    inbound_flight_date,
    inbound_flight_end,
    inbound_flight_start,
    inbound_flight_type,
    international,
    oneway,
    origin_city,
    origin_locale,
    origin_name,
    outbound_flight_date,
    outbound_flight_end,
    outbound_flight_start,
    outbound_flight_type,
    travel_token,
  } = dto;

  const result = {
    advanceDays: advance_days,
    destinationCity: destination_city,
    destinationLocale: destination_locale,
    destinationName: destination_name,
    id: id,
    inboundDate: inbound_flight_date,
    inboundEnd: inbound_flight_end,
    inboundStart: inbound_flight_start,
    inboundType: inbound_flight_type,
    international: international,
    oneway: oneway,
    originCity: origin_city,
    originLocale: origin_locale,
    originName: origin_name,
    outboundDate: outbound_flight_date,
    outboundEnd: outbound_flight_end,
    outboundStart: outbound_flight_start,
    outboundType: outbound_flight_type,
    token: flight_token,
    travelToken: travel_token,
  };

  return result;
};

export const HotelFactory = (
  dto: Partial<
    Record<
      | "advance_days"
      | "hotel_city"
      | "hotel_continent"
      | "hotel_country"
      | "hotel_end_date"
      | "hotel_initial_date"
      | "hotel_latitude"
      | "hotel_longitude"
      | "hotel_state"
      | "hotel_token"
      | "id"
      | "travel_token",
      any
    >
  >,
) => {
  const {
    id,
    hotel_token,
    travel_token,
    hotel_latitude,
    hotel_longitude,
    hotel_city,
    hotel_state,
    hotel_country,
    hotel_continent,
    hotel_initial_date,
    hotel_end_date,
    advance_days,
  } = dto;

  const result = {
    advanceDays: advance_days,
    city: hotel_city,
    continent: hotel_continent,
    country: hotel_country,
    endDate: hotel_end_date,
    id: id,
    initialDate: hotel_initial_date,
    latitude: hotel_latitude,
    logintude: hotel_longitude,
    state: hotel_state,
    token: hotel_token,
    travelToken: travel_token,
  };

  return result;
};

export type BookTravelDto = {
  billingProfileToken: string;
  companyAreaToken?: string | null;
  costCenterToken?: string | null;
  credits?: {
    consumerOfferToken: string;
    tokens?: string[];
  };
  feeCreditCardToken: string | null;
  flightPurposeToken?: string | null;
  hotelPurposeToken?: string | null;
  offers: {
    creditCardProvider?: string;
    creditCardToken?: string;
    offerToken: string;
    paymentInfo: {
      creditCard?: {
        provider: string;
        token: string;
      };
      type: string;
    };
  }[];
  orderUUID: string;
  projectToken?: string | null;
  tripPurposeToken?: string | null;
};

export const parseBookTravelRequestBody = (dto: BookTravelDto) => {
  const bookTravelRequestBody: BookTravelRequestBody = {
    billing_profile_token: dto.billingProfileToken,
    company_area_token: dto.companyAreaToken ? dto.companyAreaToken : undefined,
    cost_center_token: dto.costCenterToken ? dto.costCenterToken : undefined,
    ...(dto.credits && {
      credits: {
        consumer_offer_token: dto.credits.consumerOfferToken,
        tokens: dto.credits.tokens,
      },
    }),
    fee_credit_card_token: dto.feeCreditCardToken,
    flight_purpose_token: dto.flightPurposeToken
      ? dto.flightPurposeToken
      : null,
    hotel_purpose_token: dto.hotelPurposeToken ? dto.hotelPurposeToken : null,
    offers: dto.offers.map((offer) => ({
      credit_card_provider: offer.creditCardProvider
        ? offer.creditCardProvider
        : undefined,
      credit_card_token: offer.creditCardToken
        ? offer.creditCardToken
        : undefined,
      offer_token: offer.offerToken,
      payment_info: {
        credit_card: offer.paymentInfo.creditCard,
        type: offer.paymentInfo.type,
      },
    })),
    orderUUID: dto.orderUUID,
    project_token: dto.projectToken ? dto.projectToken : undefined,
    trip_purpose_token: dto.tripPurposeToken ? dto.tripPurposeToken : null,
  };

  return bookTravelRequestBody;
};

export const parseTravelApproval = (dto: GetTravelApprovalResponse) => {
  const { emittedOffers, offersToReview } = dto;

  const travelApproval: TravelApproval = {
    emittedOffers,
    offersToReview,
  };

  return travelApproval;
};

export const parseTravelFlightCredits = (
  dto: GetTravelFlightCreditsResponse,
) => {
  return Object.entries(dto).reduce(
    (prev, [offerToken, travelFlightCredit]) => {
      prev[offerToken] = {
        consumerOfferToken: offerToken,
        expirationDate: travelFlightCredit.expiration_date,
        initialUsageDate: travelFlightCredit.initial_usage_date,
        international: !!travelFlightCredit.international,
        operationalId: travelFlightCredit.operational_id,
        providerCreditToken: travelFlightCredit.provider_credit_token,
        value: travelFlightCredit.value,
      } as TravelFlightCredit;

      return prev;
    },
    {} as TravelFlightCredits,
  );
};

export const parseTravelPayment = (dto: GetTravelPaymentResponse) => {
  const travelPayment: TravelPayment = {
    ...dto,
  };

  return travelPayment;
};

export const parseTravelRemainingBudgets = (
  dtos: GetTravelRemainingBudgetsResponse,
) => {
  return dtos.map((dto) => {
    const budget: Budget = {
      actions: dto.budget.actions.map((action) => ({
        action: action.action,
        actionFrequency: action.action_frequency,
        actionTargetToken: action.action_target_token,
        actionThreshold: action.action_threshold,
        budgetToken: action.budget_token,
        createdAt: action.created_at,
        token: action.token,
        updatedAt: action.updated_at,
      })),
      active: dto.budget.active,
      billingProfileToken: dto.budget.billing_profile_token,
      clientToken: dto.budget.client_token,
      costCenterToken: dto.budget.cost_center_token,
      createdAt: dto.budget.created_at,
      month: dto.budget.month,
      name: dto.budget.name,
      projectToken: dto.budget.project_token,
      semester: dto.budget.semester,
      timeframe: dto.budget.timeframe,
      timesViolated: dto.budget.timesViolated,
      token: dto.budget.token,
      trimester: dto.budget.trimester,
      updatedAt: dto.budget.updated_at,
      value: dto.budget.value,
      year: dto.budget.year,
    };

    const travelRemainingBudget: TravelRemainingBudget = {
      budget,
      remainingBudget: dto.remaining_budget,
      totalSpent: dto.total_spent,
    };

    return travelRemainingBudget;
  });
};

export const TravelFactory = (
  dto: Partial<
    Record<
      | "advance_days"
      | "approval_created_at"
      | "approval_status"
      | "approver_stage"
      | "approver_token"
      | "billing_profile_token"
      | "booker_token"
      | "booking_fee_status"
      | "canceled"
      | "company_area_token"
      | "concluded"
      | "copy_from"
      | "copy_status"
      | "cost_center_token"
      | "created_at"
      | "draft"
      | "expense_report_token"
      | "expired"
      | "fee_paid_at"
      | "fee_paid"
      | "fee_value"
      | "flight_purpose_token"
      | "flight" // todo: retira
      | "friend_house_status"
      | "hasAlteration"
      | "hasCancelation"
      | "hasInsurance"
      | "hotel_purpose_token"
      | "hotel" // todo: retirar
      | "id"
      | "ongoing"
      | "project_token"
      | "rawEndTravel"
      | "rawStartTravel"
      | "scheduled"
      | "services"
      | "status"
      | "travel_end"
      | "travel_name"
      | "travel_start"
      | "travel_token"
      | "traveled"
      | "traveler_token"
      | "trip_purpose_token",
      any
    >
  >,
) => {
  const {
    advance_days,
    approval_created_at,
    approval_status,
    approver_stage,
    approver_token,
    billing_profile_token,
    booker_token,
    booking_fee_status,
    canceled,
    company_area_token,
    concluded,
    copy_from,
    copy_status,
    cost_center_token,
    created_at,
    draft,
    expense_report_token,
    expired,
    fee_paid_at,
    fee_paid,
    fee_value,
    flight_purpose_token,
    flight, // todo: retirar
    friend_house_status,
    hasAlteration,
    hasCancelation,
    hasInsurance,
    hotel_purpose_token,
    hotel, // todo: retirar
    id,
    ongoing,
    project_token,
    rawEndTravel,
    rawStartTravel,
    scheduled,
    services,
    status,
    travel_end,
    travel_name,
    travel_start,
    travel_token,
    traveled,
    traveler_token,
    trip_purpose_token,
  } = dto;

  const result = {
    advanceDays: advance_days,
    approvalCreatedAt: approval_created_at ? approval_created_at : null,
    approvalStatus: approval_status,
    approverStage: approver_stage,
    approverToken: approver_token,
    billingProfileToken: billing_profile_token,
    bookerToken: booker_token,
    bookingFeeStatus: booking_fee_status,
    canceled: canceled,
    companyAreaToken: company_area_token,
    concluded: concluded,
    copyFrom: copy_from,
    copyStatus: copy_status,
    costCenterToken: cost_center_token,
    createdAt: created_at,
    draft: draft,
    expenseReportToken: expense_report_token,
    expired: expired,
    feePaid: fee_paid,
    feePaidAt: fee_paid_at,
    feeValue: fee_value,
    flight: flight,
    flightPurposeToken: flight_purpose_token,
    friendHouseStatus: friend_house_status,
    hasAlteration: hasAlteration,
    hasCancelation: hasCancelation,
    hasInsurance: hasInsurance,
    hotel: hotel,
    hotelPurposeToken: hotel_purpose_token,
    id: id,
    name: travel_name,
    ongoing: ongoing,
    projectToken: project_token,
    rawEndTravel: rawEndTravel,
    rawStartTravel: rawStartTravel,
    scheduled: scheduled,
    services: services,
    status: status,
    token: travel_token,
    traveled: traveled,
    travelEnd: travel_end,
    travelerToken: traveler_token,
    travelStart: travel_start,
    tripPurposeToken: trip_purpose_token,
  };

  return result;
};

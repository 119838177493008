import React from "react";

import { TripsTabsFilter } from "~/apps/shared/constants/enums";

import { Button } from "@toolkit/v2";

import { useTravelsFiltersActions, useTravelsList } from "../trips.context";
import { styles } from "./styles";

const tabs = [
  { label: "Todas", value: TripsTabsFilter.ALL },
  { label: "Rascunho", value: TripsTabsFilter.DRAFT },
  { label: "Em breve", value: TripsTabsFilter.UPCOMING },
  { label: "Agora", value: TripsTabsFilter.NOW },
  { label: "Passadas", value: TripsTabsFilter.PAST },
  { label: "Canceladas", value: TripsTabsFilter.CANCELED },
];

export const TripsTabs: React.FC = () => {
  const { tabFilter, handleChangeTabFilter } = useTravelsFiltersActions();
  const { travelsCount } = useTravelsList();

  const countIsReset = Object.values(travelsCount).every(
    (count) => count === 0,
  );

  return (
    <nav css={styles.root}>
      <ul css={styles.tabs.root}>
        {tabs.map((tab, index) => (
          <li css={styles.tabs.tab.root} key={tab.value}>
            {index !== 0 ? <div css={styles.tabs.tab.divider} /> : null}
            <Button
              css={styles.tabs.tab.button}
              fill={tabFilter === tab.value ? "filled" : "outlined"}
              onClick={() => {
                handleChangeTabFilter(tab.value);
              }}
              variant={tabFilter === tab.value ? "pink" : "default"}
            >
              {tab.label}{" "}
              {countIsReset
                ? null
                : `(${travelsCount[tab.value]
                    .toLocaleString()
                    .replace(",", ".")})`}
            </Button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

import React, { useCallback } from "react";
import Cards from "react-credit-cards";
import { useQueryClient } from "react-query";

import { useCards } from "~/apps/corporate/contexts/cards.context";
import { invalidateBillingProfilePayableCardsForTravel } from "~/apps/corporate/pages/travels/payment/payment-content/payment-decision/payment-payment.hooks";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { InputMasked } from "~/apps/shared/components/input-masked/input-masked";
import { Input } from "~/apps/shared/components/input/input";
import {
  CARD_ISSUERS,
  CREDIT_CARD_MASKS,
} from "~/apps/shared/constants/credit-card.constant";
import { logger } from "~/apps/shared/utils/logger";
import { removeBlankSpaces } from "~/apps/shared/utils/remove-blank-spaces";

import { Button } from "@toolkit/v2";

import {
  createPaymentMethodSchema,
  CreatePaymentMethodSchema,
} from "./create-payment-method.schema";
import { styles } from "./styles";

const serviceTypeOptions = [
  {
    key: "enableToBus",
    label: "Ônibus",
  },
  {
    key: "enableToCar",
    label: "Carro",
  },
  {
    key: "enableToFlight",
    label: "Aéreo",
  },
  {
    key: "enableToHotel",
    label: "Hotel",
  },
  {
    key: "enableToRide",
    label: "Táxi",
  },
  {
    key: "enableToOther",
    label: "Fee de Viagem e Outros",
  },
];

type Props = {
  back: () => void;
};

export const CreatePaymentMethod: React.FC<Props> = ({ back }) => {
  const queryClient = useQueryClient();

  const { createCard, isLoadingCreateCard, isLoadingEditCard } = useCards();

  const form = useForm<CreatePaymentMethodSchema>({
    defaultValues: {
      allowApprovers: false,
      billingProfileToken: null,
      cvv: "",
      description: "",
      enableToBus: false,
      enableToCar: false,
      enableToClient: false,
      enableToFlight: false,
      enableToHotel: false,
      enableToOther: false,
      enableToRide: false,
      expirationDate: "",
      holderName: "",
      issuer: {},
      number: "",
    },
    onSubmit: async () => {
      const values = form.getValues();

      const sucess = await createCard({
        allowApprovers: values.allowApprovers,
        billingProfileToken: values.billingProfileToken,
        cvv: values.cvv,
        description: values.description,
        enableToBus: values.enableToBus,
        enableToCar: values.enableToCar,
        enableToClient: values.enableToClient,
        enableToFlight: values.enableToFlight,
        enableToHotel: values.enableToHotel,
        enableToOther: values.enableToOther,
        enableToRide: values.enableToRide,
        expirationDate: values.expirationDate,
        holderName: values.holderName,
        issuer: values.issuer,
        number: values.number,
      });

      if (!sucess) {
        return;
      }

      void invalidateBillingProfilePayableCardsForTravel(queryClient);

      back();
    },
    schema: createPaymentMethodSchema,
  });

  const { cvv, expirationDate, holderName, issuer, number } = form.watch([
    "cvv",
    "expirationDate",
    "holderName",
    "issuer",
    "number",
  ]);

  const getCreditCardMask = useCallback(
    ({ value }: { value: string }) => {
      let maskLength: number | null = 16;

      const formattedValue = removeBlankSpaces(value);
      const numberOfBlankSpaces = value.length - formattedValue.length;

      const maxLength = issuer ? Math.max.apply(null, issuer.lengths) : 16;

      if (formattedValue.length >= maxLength) {
        maskLength = maxLength;
      } else if (issuer) {
        maskLength =
          issuer.lengths.find((length: number) => {
            return formattedValue.length <= length;
          })! + numberOfBlankSpaces;
      }

      if (!maskLength || !(maskLength in CREDIT_CARD_MASKS)) {
        return false;
      }

      return CREDIT_CARD_MASKS[maskLength as keyof typeof CREDIT_CARD_MASKS];
    },
    [issuer],
  );

  const updateIssuer = useCallback(
    (cardNumber: string) => {
      const cleanedCardNumber = removeBlankSpaces(cardNumber);

      const issuer = CARD_ISSUERS.find(
        (item) => !!cleanedCardNumber.match(item.startsWith),
      );

      if (!issuer) {
        logger.error(`issuer not found: ${cleanedCardNumber}`);

        return;
      }

      form.setValue("issuer", issuer);
    },
    [form],
  );

  console.log(form.errors);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.body.root}>
        <div css={styles.body.card.root}>
          <div css={styles.body.card.left}>
            <div css={styles.body.input.root}>
              <span css={styles.body.input.label}>Nome do Portador*</span>
              <Form.Field
                name="holderName"
                render={({ onChange, value }) => (
                  <Input
                    css={styles.body.input.input}
                    onChange={onChange}
                    placeholder="Digite o nome do portador do cartão..."
                    value={value}
                  />
                )}
              />
              {form.errors["holderName"] ? (
                <InputErrorMessage>
                  {form.errors["holderName"].message}
                </InputErrorMessage>
              ) : (
                <span css={styles.body.input.info}>
                  Exatamente igual ao cartão.
                </span>
              )}
            </div>
            <div css={styles.body.input.root}>
              <span css={styles.body.input.label}>Número do cartão*</span>
              <Form.Field
                name="number"
                render={({ onChange, value }) => (
                  <InputMasked
                    css={styles.body.input.input}
                    mask={getCreditCardMask({ value })}
                    onChange={(event) => {
                      onChange(event);

                      updateIssuer(event.target.value);
                    }}
                    placeholder="Digite o nome do número do cartão..."
                    value={value}
                  />
                )}
              />
              <InputErrorMessage>
                {form.errors["number"]?.message}
              </InputErrorMessage>
            </div>
          </div>
          <div css={styles.body.card.right.root}>
            <Cards
              acceptedCards={[
                "amex",
                "dinersclub",
                "elo",
                "mastercard",
                "visa",
              ]}
              expiry={expirationDate}
              cvc={cvv}
              locale={{
                valid: "Válido até",
              }}
              name={holderName}
              number={number}
              placeholders={{
                name: "Keanu Reeves",
              }}
            />
          </div>
        </div>
        <div css={styles.body.row}>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>Data de expiração*</span>
            <Form.Field
              name="expirationDate"
              render={({ onChange, value }) => (
                <InputMasked
                  css={styles.body.input.input}
                  maskType="month-and-year"
                  onChange={onChange}
                  placeholder="Digite a data de expiração..."
                  value={value}
                />
              )}
            />
            <InputErrorMessage>
              {form.errors["expirationDate"]?.message}
            </InputErrorMessage>
          </div>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>CVV*</span>
            <Form.Field
              name="cvv"
              render={({ onChange, value }) => (
                <InputMasked
                  css={styles.body.input.input}
                  maskType="cvv"
                  onChange={onChange}
                  placeholder="Digite o CVV..."
                  value={value}
                />
              )}
            />
            <InputErrorMessage>{form.errors["cvv"]?.message}</InputErrorMessage>
          </div>
        </div>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>Apelido do cartão*</span>
          <Form.Field
            name="description"
            render={({ onChange, value }) => (
              <Input
                css={styles.body.input.input}
                onChange={onChange}
                placeholder="Digite um apelido para o cartão..."
                value={value}
              />
            )}
          />
          <InputErrorMessage>
            {form.errors["description"]?.message}
          </InputErrorMessage>
        </div>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>Finalidades do cartão</span>
          <div css={styles.body.services.root}>
            {serviceTypeOptions.map((option) => (
              <label css={styles.body.services.checkbox} key={option.key}>
                <Checkbox
                  checked={form.watch(option.key)}
                  onChange={(e) => {
                    form.setValue(option.key, e.target.checked);
                  }}
                  variant="pink"
                />
                <span css={styles.body.services.label}>{option.label}</span>
              </label>
            ))}
          </div>
          <InputErrorMessage>
            {form.errors["at-least-one-service-enabled"]?.message}
          </InputErrorMessage>
        </div>
        <label css={styles.body.services.checkbox}>
          <Checkbox
            checked={form.watch("allowApprovers")}
            onChange={(e) => {
              form.setValue("allowApprovers", e.target.checked);
            }}
            variant="pink"
          />
          <span css={styles.body.services.label}>
            Viabilizar o uso deste cartão em minhas viagens por parte de
            aprovadores e solicitantes.
          </span>
        </label>
      </div>
      <div css={styles.footer.root}>
        <Button
          disabled={
            form.formState.isSubmitting ||
            isLoadingCreateCard ||
            isLoadingEditCard
          }
          fill="outlined"
          onClick={() => {
            back();
          }}
          type="button"
        >
          Cancelar
        </Button>
        <Button
          disabled={
            form.formState.isSubmitting ||
            isLoadingCreateCard ||
            isLoadingEditCard
          }
          variant="pink"
        >
          Salvar
        </Button>
      </div>
    </Form>
  );
};

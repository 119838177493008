import React, {
  // useCallback,
  useEffect,
} from "react";

import { navigate, RouteComponentProps } from "@reach/router";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { getParamsFromLocation } from "~/apps/shared/utils/get-params-from-location";

import { CircularSpinner } from "@toolkit/v2";

import { PageTitle } from "../../components/page-title/page-title";
import { pageTitles } from "../../constants/page-titles";
import { useApplication } from "../../contexts/application.context";
import { useUser } from "../../contexts/user.context";
import { getUserFromAccessToken } from "../../helpers/user.helper";
import { styles } from "./styles";

type Props = RouteComponentProps;

export const OIDCCallback: React.FC<Props> = () => {
  const { showSnackMessage } = useApplication();
  const { fetchUser } = useUser();

  useEffect(() => {
    const location = window.location;

    const params = getParamsFromLocation(location);

    if (params && "accessToken" in params) {
      const login = async () => {
        const { accessToken } = params;

        const { userToken } = getUserFromAccessToken(accessToken);

        localStorage.setItem("access_token", accessToken);

        await fetchUser(userToken);

        navigate("/");
      };

      void login();

      return;
    }

    showSnackMessage(
      "Erro ao realizar login, tente novamente.",
      ALERT_TYPES.ERROR,
    );

    navigate("/login");
  }, [fetchUser, showSnackMessage]);

  return (
    <>
      <PageTitle title={pageTitles.BASE_TITLE} />
      <div css={styles.root}>
        <CircularSpinner />
      </div>
    </>
  );
};

import moment from "moment";

import { GetUserRecentHotelsResponse } from "../dtos/hotel.dto";
import { RecentHotelsSearch } from "../pages/new-trip/new-trip.types";

export const parseUserRecentHotels = (dto: GetUserRecentHotelsResponse) => {
  return dto.map((recentHotel) => {
    const recentHotelsSearch: RecentHotelsSearch = {
      endDate: moment(recentHotel.endDate),
      location: {
        city: recentHotel.location.city,
        country: recentHotel.location.country,
        latitude: parseFloat(recentHotel.location.latitude),
        longitude: parseFloat(recentHotel.location.longitude),
        search: recentHotel.location.search,
        state: recentHotel.location.state,
        traveler: recentHotel.traveler,
      },
      startDate: moment(recentHotel.startDate),
      totalGuests: recentHotel.totalGuests,
      traveler: recentHotel.traveler,
    };

    return recentHotelsSearch;
  });
};

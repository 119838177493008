import { api } from "~/apps/shared/services/api";

import {
  LoyaltyProgramResponseBody,
  SaveLoyaltyProgramRequestBody,
} from "../dtos/loyalty-program.dto";
import { getAuthorizationHeader } from "../helpers/user.helper";
import * as loyaltyProgramParser from "../parsers/loyalty-program.parser";

export async function createLoyaltyProgram(
  data: SaveLoyaltyProgramRequestBody,
) {
  return await api
    .request<LoyaltyProgramResponseBody>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/loyalty-programs",
    })
    .then(({ data }) => loyaltyProgramParser.parseLoyaltyProgram(data));
}

export async function getUserLoyaltyPrograms(userToken: string) {
  return await api
    .request<LoyaltyProgramResponseBody[]>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/users/${userToken}/loyalty-programs`,
    })
    .then(({ data }) => data.map(loyaltyProgramParser.parseLoyaltyProgram));
}

export async function inactiveLoyaltyProgram(loyaltyProgramToken: string) {
  return await api.request({
    headers: getAuthorizationHeader(),
    method: "PUT",
    url: `/booking/loyalty-programs/${loyaltyProgramToken}/inactive`,
  });
}

export async function updateLoyaltyProgram(
  data: SaveLoyaltyProgramRequestBody,
  loyaltyProgramToken: string,
) {
  return await api
    .request<LoyaltyProgramResponseBody>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/loyalty-programs/${loyaltyProgramToken}`,
    })
    .then(({ data }) => loyaltyProgramParser.parseLoyaltyProgram(data));
}

import React, { useState, createContext, useEffect, useCallback } from "react";

import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import { ClientTag, Tag, TravelTag } from "../../../../../models/tags.models";
import * as tagsService from "./tags.service";

type State = {
  isLoadingTagOptions: boolean;
  repeatedTag: boolean;
  tagInputValue: string;
  tagOptions: ClientTag[];
  travelsTags:
    | {
        [travelToken: string]: TravelTag[] | undefined;
      }
    | undefined;
};

const initialState: State = {
  isLoadingTagOptions: false,
  repeatedTag: false,
  tagInputValue: "",
  tagOptions: [],
  travelsTags: {},
};

type TagsContextProps = State & {
  addTravelTag: (
    tag: {
      label: string;
    } & (
      | {
          isNewOption: true;
        }
      | {
          isNewOption: false;
          value: string;
        }
    ),
    travelToken: string,
  ) => Promise<void>;
  removeTravelTag: (travelTagToken: string) => Promise<void>;
  loadTagOptions: () => Promise<void>;
  loadTravelTags: (travelToken: string) => Promise<void>;
  setTravelsTags: (tags: {
    [travelToken: string]: TravelTag[] | undefined;
  }) => void;
};

const TagsContext = createContext({} as TagsContextProps);

export const TagsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useState(initialState);

  const updateTagOptionsWithCreatedTag = useCallback((tag: Tag) => {
    setState((prev) => ({
      ...prev,
      tagOptions: [
        ...prev.tagOptions,
        {
          ...tag,
          active: true,
          createdAt: new Date().toISOString(),
          createdBy: "",
        },
      ],
    }));
  }, []);

  const createTag = useCallback(
    async (tag: { label: string }) => {
      if (!user) {
        return;
      }

      const { tagOptions } = state;

      const formattedTagName = tag.label.trim();

      const tagAlreadyExists = tagOptions.some(
        (item) =>
          item.tagName.toLowerCase().trim() === formattedTagName.toLowerCase(),
      );

      if (tagAlreadyExists) {
        setState((prev) => ({
          ...prev,
          repeatedTag: true,
        }));

        return;
      }

      const createTagResponse = await tagsService.createTag(
        formattedTagName,
        user.getUserToken(),
      );

      if (createTagResponse.error) {
        const error = createTagResponse.error;

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      if (!createTagResponse.data) {
        const error = ERROR.UNEXPECTED;

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      updateTagOptionsWithCreatedTag(createTagResponse.data!);

      return createTagResponse.data!;
    },
    [
      showSnackMessage,
      state.tagOptions,
      state.repeatedTag,
      updateTagOptionsWithCreatedTag,
      user,
    ],
  );

  const addTravelTag = useCallback(
    async (
      tag: {
        label: string;
      } & (
        | {
            isNewOption: true;
          }
        | {
            isNewOption: false;
            value: string;
          }
      ),
      travelToken: string,
    ) => {
      const { travelsTags } = state;

      if (!travelsTags) {
        return;
      }

      const travelTags = travelsTags[travelToken] || [];

      let tagName = tag.label;
      let tagToken = !tag.isNewOption ? tag.value : undefined;

      if (tag.isNewOption) {
        const createdTag = await createTag({ label: tagName });

        if (!createdTag) {
          return;
        }

        tagName = createdTag.tagName;
        tagToken = createdTag.tagToken;
      }

      const isTagAlreadyAppliedToTravel = travelTags.some(
        (travelTag) => travelTag.tagToken === tagToken,
      );

      if (isTagAlreadyAppliedToTravel) {
        setState((prev) => ({
          ...prev,
          repeatedTag: true,
        }));

        return;
      }

      const travelTag: TravelTag = {
        tagName,
        tagToken: tagToken!,
        travelTagToken: "",
      };

      const updatedState = {
        ...state,
        repeatedTag: false,
        travelsTags: {
          ...state.travelsTags,
          [travelToken]: [...travelTags, travelTag],
        },
      };

      setState(updatedState);

      void (async () => {
        const addTravelTagResponse = await tagsService.addTravelTag(
          tagToken!,
          travelToken,
        );

        if (addTravelTagResponse.error) {
          const error = addTravelTagResponse.error;

          setState(state);

          showSnackMessage(error.description, ALERT_TYPES.ERROR);

          return;
        }

        const { travelTagToken } = addTravelTagResponse.data!;

        const travelTag: TravelTag = {
          tagName,
          tagToken: tagToken!,
          travelTagToken,
        };

        const travelTags = updatedState.travelsTags[travelToken]!;

        setState({
          ...updatedState,
          repeatedTag: false,
          travelsTags: {
            ...updatedState.travelsTags,
            [travelToken]: travelTags!.map((t) =>
              t.tagToken === tagToken ? travelTag : t,
            ),
          },
        });
      })();
    },
    [createTag, showSnackMessage, state],
  );

  const removeTravelTag = useCallback(
    async (travelTagToken: string) => {
      const { travelsTags } = state;

      if (!travelsTags) {
        return;
      }

      const travelToken = Object.keys(travelsTags).find((travelToken) =>
        travelsTags[travelToken]?.some(
          (travelTag) => travelTag.travelTagToken === travelTagToken,
        ),
      );

      if (!travelToken) {
        return;
      }

      const travelTags = travelsTags[travelToken]!;

      const updatedState = {
        ...state,
        travelsTags: {
          ...state.travelsTags,
          [travelToken]: travelTags.filter(
            (travelTag) => travelTag.travelTagToken !== travelTagToken,
          ),
        },
      };

      setState(updatedState);

      void (async () => {
        const removeTravelTagResponse = await tagsService.removeTravelTag(
          travelTagToken,
        );

        if (removeTravelTagResponse.error) {
          const error = removeTravelTagResponse.error;

          setState(state);

          showSnackMessage(error.description, ALERT_TYPES.ERROR);

          return;
        }
      })();
    },
    [state],
  );

  const loadTagOptions = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingTagOptions: true,
    }));

    const getClientTagsResponse = await tagsService.getClientTags();

    if (getClientTagsResponse.error) {
      const error = getClientTagsResponse.error;

      setState((prev) => ({
        ...prev,
        isLoadingTagOptions: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!getClientTagsResponse.data) {
      const error = ERROR.UNEXPECTED;

      setState((prev) => ({
        ...prev,
        isLoadingTagOptions: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoadingTagOptions: false,
      tagOptions: getClientTagsResponse.data!,
    }));
  }, [showSnackMessage]);

  const loadTravelTags = useCallback(async (travelToken: string) => {
    const getTravelTagsResponse = await tagsService.getTravelTags(travelToken);

    if (!getTravelTagsResponse.data || getTravelTagsResponse.error) {
      return;
    }

    setState((prev) => ({
      ...prev,
      travelsTags: {
        ...prev.travelsTags,
        [travelToken]: getTravelTagsResponse.data!,
      },
    }));
  }, []);

  const setTravelsTags = (tags: {
    [travelToken: string]: TravelTag[] | undefined;
  }) => {
    setState((prev) => ({
      ...prev,
      travelsTags: tags,
    }));
  };

  useEffect(() => {
    void loadTagOptions();
  }, [loadTagOptions]);

  return (
    <TagsContext.Provider
      value={{
        ...state,
        addTravelTag,
        removeTravelTag,
        loadTagOptions,
        loadTravelTags,
        setTravelsTags,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

export const useTags = useContextFactory("TagsContext", TagsContext);

import { css } from "@styled-system/css";

export const styles = {
  root: ({ size }: { size: number }) =>
    css({
      alignItems: "center",
      aspectRatio: "1",
      display: "flex",
      flexShrink: 0,
      height: `${size}px`,
      justifyContent: "center",
      pointerEvents: "auto",
      width: `${size}px`,
    }),
};

import React, { useCallback, useState } from "react";

import { Link, navigate, RouteComponentProps } from "@reach/router";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { InputPassword } from "~/apps/shared/components/input-password/input-password";
import { Input } from "~/apps/shared/components/input/input";
import { Logo } from "~/apps/shared/components/logo/logo";
import { ALERT_TYPES } from "~/apps/shared/constants";
import {
  AUTHENTICATION_ERRORS,
  AUTHENTICATION_ERRORS_MESSAGES,
} from "~/apps/shared/constants/errors";
import { useLocationState } from "~/apps/shared/hooks/use-location-state";
import { Error } from "~/apps/shared/types";

import { Button } from "@toolkit/v2";

import { AuthLayout } from "../../components/auth-layout/auth-layout";
import { PageTitle } from "../../components/page-title/page-title";
import { pageTitles } from "../../constants/page-titles";
import { useApplication } from "../../contexts/application.context";
import { useUser } from "../../contexts/user.context";
import { loginFormSchema, LoginFormSchema } from "./login-form.schema";
import { styles } from "./styles";

type Props = RouteComponentProps;

export const Login: React.FC<Props> = () => {
  const { loadGeneralData, showSnackMessage } = useApplication();
  const { isLoadingLogin, login } = useUser();

  const [loginError, setLoginError] = useState<Error | null>(null);

  const checkForRedirectErrors = useCallback(
    (params: any) => {
      if (params.err) {
        const errorMessage =
          AUTHENTICATION_ERRORS_MESSAGES[params.err] ||
          AUTHENTICATION_ERRORS_MESSAGES[AUTHENTICATION_ERRORS.EXPIRED_SESSION];

        showSnackMessage(errorMessage, ALERT_TYPES.INFO, {
          autoHideDuration: 10000,
        });
      }
    },
    [showSnackMessage],
  );

  const form = useForm<LoginFormSchema>({
    defaultValues: {
      email: "",
      password: "",
    },
    onSubmit: async () => {
      const values = form.getValues();

      setLoginError(null);

      const loginResponse = await login({
        email: values.email,
        password: values.password,
      });

      if (loginResponse.isFailure()) {
        const error = loginResponse.data;

        setLoginError(error);

        return;
      }

      await loadGeneralData();

      const user = loginResponse.data;

      if (!user.hasSearchCapability()) {
        navigate("/trips");

        return;
      }

      navigate("/");
    },
    schema: loginFormSchema,
  });

  useLocationState(checkForRedirectErrors);

  return (
    <>
      <PageTitle title={pageTitles.BASE_TITLE} />
      <AuthLayout>
        <Logo showName />
        <Form context={form} css={styles.form.root}>
          <div css={styles.form.body}>
            {!form.submitting && loginError ? (
              <div css={styles.form.error}>
                <Icon use="x-circle-outline" />
                <span>{loginError.description}</span>
              </div>
            ) : null}
            <div css={styles.form.field.root}>
              <Form.Field
                as={
                  <Input
                    aria-label="Email"
                    autoFocus
                    id="email"
                    placeholder="Email"
                    type="email"
                  />
                }
                css={styles.form.field.input}
                onChange={() => {
                  void form.trigger("email");
                }}
                name="email"
              />
              <InputErrorMessage css={styles.form.field.error}>
                {form.errors["email"]?.message}
              </InputErrorMessage>
            </div>
            <div css={styles.form.field.root}>
              <Form.Field
                name="password"
                onChange={() => {
                  void form.trigger("password");
                }}
                render={({ ...props }) => (
                  <InputPassword
                    aria-label="Senha"
                    name="password"
                    id="password"
                    {...props}
                  />
                )}
              />
              <InputErrorMessage css={styles.form.field.error}>
                {form.errors["password"]?.message}
              </InputErrorMessage>
            </div>
            <Button
              css={styles.form.button}
              disabled={form.submitting || isLoadingLogin}
            >
              {!form.submitting ? "Entrar" : "Entrando..."}
            </Button>
            <span css={styles.form.forgot.text}>
              Esqueceu sua senha?{" "}
              <Link css={styles.form.forgot.link} to="/password/reset">
                Clique aqui.
              </Link>
            </span>
          </div>
        </Form>
        <hr css={styles.divisor} />
        <div css={styles.footer.root}>
          <Link css={styles.footer.sso} to="/sso-login">
            Entrar com SSO
          </Link>
        </div>
      </AuthLayout>
    </>
  );
};

import React from "react";

import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { LazyLoadedRoute } from "~/apps/shared/components/lazy-loaded-route/lazy-loaded-route";

import { ScrollToTop } from "../../components/scroll-to-top/scroll-to-top";
import { PasswordRedefine } from "./password-redefine/password-redefine";
import { PasswordReset } from "./password-reset/password-reset";
import { styles } from "./styles";

type Props = RouteComponentProps;

const Outlet: React.FC = () => {
  const PasswordUpdate = React.lazy(() =>
    import("./password-update/password-update").then((module) => ({
      default: module.PasswordUpdate,
    })),
  );

  return (
    <Router css={styles.router} primary={false}>
      <PasswordRedefine path="/redefine/:redefineToken" />
      <PasswordReset path="/reset" />
      <LazyLoadedRoute component={PasswordUpdate} path="/update/*" />
      <Redirect from="*" noThrow to="/" />
    </Router>
  );
};

export const Password: React.FC<Props> = () => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  );
};

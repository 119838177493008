import { Icons } from "~/apps/shared/components/icon/icon";
import { CurrencyCode, OfferStatus } from "~/apps/shared/constants/enums";
import { differenceInDays } from "~/apps/shared/utils/difference-in-days";
import { toCurrency } from "~/apps/shared/utils/to-currency";
import moment from "moment";

import { getPresentationServicesGroupedByOfferStatus } from "../../helpers/itinerary.helper";
import {
  BusItineraryServiceModel,
  CarItineraryServiceModel,
  FlightItineraryServiceModel,
  HotelItineraryServiceModel,
  ItineraryBusJourney,
  ItineraryFlightJourney,
  ItineraryService,
  ItineraryServiceModel,
  ItineraryServiceSuggestion,
} from "./itinerary-service.model";

export abstract class ItineraryServicePresenter {
  public suggestion: ItineraryServiceSuggestion | null = null;

  constructor(protected serviceModel: ItineraryServiceModel) {}

  public belongsToCanceledTab() {
    return this.isCanceled();
  }

  public belongsToFrustratedPurchasesTab() {
    return (
      !this.isEmitted() &&
      (this.isApprovalDeclined() ||
        this.isBookingFailed() ||
        (this.isDraft() && this.isOutdated()) ||
        this.isUnavailable())
    );
  }

  public belongsToTravelPlanTab() {
    return (
      !this.belongsToCanceledTab() && !this.belongsToFrustratedPurchasesTab()
    );
  }

  public getBookingCode() {
    return this.serviceModel.getBookingCode();
  }

  public getBookingFailedReason() {
    return this.serviceModel.getBookingFailedReason();
  }

  public getCancelationInfo() {
    return this.serviceModel.getCancelationInfo();
  }

  public getChangeFee() {
    return this.serviceModel.getChangeFee();
  }

  public getChangedPrice() {
    return this.serviceModel.getChangedPrice();
  }

  public getChoiceJustification() {
    return this.serviceModel.getChoiceJustification();
  }

  public getCurrency() {
    return this.serviceModel.getCurrency();
  }

  public getFreeCancelationUntil() {
    return this.serviceModel.getFreeCancelationUntil();
  }

  public abstract getFormattedFreeCancelationUntil(): string | null;

  public abstract getFormattedName(): string | null;

  public abstract getFormattedItineraryServiceHeaderName(): string;

  public getFormattedPeriod() {
    const { endDate, startDate } = this.getPeriod();

    return `${startDate.format("ddd, DD MMM YYYY")} - ${endDate.format(
      "ddd, DD MMM YYYY",
    )}`;
  }

  public getFormattedPrice() {
    return toCurrency(this.getPrice());
  }

  public getFormattedUsedCredits() {
    return toCurrency(this.getUsedCredit());
  }

  public abstract getIcon(): Icons;

  public getOfferToken() {
    return this.serviceModel.getOfferToken();
  }

  public getOperationalId() {
    return this.serviceModel.getOperationalId();
  }

  public getPeriod() {
    return this.serviceModel.getPeriod();
  }

  public getPrice() {
    return this.serviceModel.getPrice();
  }

  public getSearch() {
    return this.serviceModel.getSearch();
  }

  public getSearchPeriod() {
    return this.serviceModel.getSearchPeriod();
  }

  public abstract getService(): ItineraryService;

  public getServiceModel() {
    return this.serviceModel;
  }

  public getSmRecommendationContext() {
    return this.serviceModel.getSmRecommendationContext();
  }

  public getStartDate() {
    return this.serviceModel.getStartDate();
  }

  public getStatus() {
    if (this.isApprovalDeclined()) {
      return OfferStatus.APPROVAL_DECLINED;
    }

    if (this.isBookingFailed()) {
      return OfferStatus.BOOKING_FAILED;
    }

    if (this.isBookingProcessing()) {
      return OfferStatus.BOOKING_PROCESSING;
    }

    if (this.isCanceled() || this.isChanged()) {
      return OfferStatus.CANCELED;
    }

    if (this.isCancelationFailedOrPending()) {
      return OfferStatus.CANCELING;
    }

    if (this.isEmitted()) {
      return OfferStatus.EMITTED;
    }

    return OfferStatus.DRAFT;
  }

  public getTotalTravelers() {
    return this.serviceModel.getTotalTravelers();
  }

  public getType() {
    return this.serviceModel.getType();
  }

  public getUsedCredit() {
    return this.serviceModel.getUsedCredit();
  }

  public hasFreeCancelation() {
    return this.serviceModel.hasFreeCancelation();
  }

  public hasUsedCredits() {
    return this.serviceModel.hasUsedCredits();
  }

  public isApprovalDeclined() {
    return this.serviceModel.isApprovalDeclined();
  }

  public isBookingFailed() {
    return this.serviceModel.isBookingFailed();
  }

  public isBookingManualFailed() {
    return this.serviceModel.isBookingManualFailed();
  }

  public isBookingProcessing() {
    return this.serviceModel.isBookingProcessing();
  }

  public isBookingUnitiated() {
    return this.serviceModel.isBookingUnitiated();
  }

  public isBusService(): this is BusItineraryServicePresenter {
    return this.serviceModel.isBusService();
  }

  public isCancelable() {
    return this.serviceModel.isCancelable();
  }

  public isCanceled() {
    return this.serviceModel.isCanceled();
  }

  public isCancelationFailedOrPending() {
    return this.serviceModel.isCancelationFailedOrPending();
  }

  public isChanged() {
    return this.serviceModel.isChanged();
  }

  public isCarService(): this is CarItineraryServicePresenter {
    return this.serviceModel.isCarService();
  }

  public isDeletable() {
    return this.serviceModel.isDeletable();
  }

  public isDeniedOnApproval() {
    return this.serviceModel.isDeniedOnApproval();
  }

  public isDraft() {
    return this.getStatus() === OfferStatus.DRAFT;
  }

  public isEmitted() {
    return this.serviceModel.isEmitted();
  }

  public isFlightService(): this is FlightItineraryServicePresenter {
    return this.serviceModel.isFlightService();
  }

  public isHotelService(): this is HotelItineraryServicePresenter {
    return this.serviceModel.isHotelService();
  }

  public isNegotiated() {
    return this.serviceModel.isNegotiated();
  }

  public isNotInBRL() {
    return this.getCurrency() !== CurrencyCode.BRL;
  }

  public isOutdated() {
    return this.serviceModel.isOutdated();
  }

  public isOutdatedOrUnavailable() {
    return this.serviceModel.isOutdatedOrUnavailable();
  }

  public isProcessable() {
    return this.serviceModel.isProcessable();
  }

  public isReserved() {
    return this.serviceModel.isReserved();
  }

  public isUnavailable() {
    return this.serviceModel.isUnavailable();
  }

  public showReservation() {
    return this.getStatus() === OfferStatus.EMITTED;
  }
}

export class BusItineraryServicePresenter extends ItineraryServicePresenter {
  public presentationJourneys: ItineraryBusJourney[] | undefined;

  constructor(protected serviceModel: BusItineraryServiceModel) {
    super(serviceModel);
  }

  public getAllJourneys() {
    return this.serviceModel.getAllJourneys();
  }

  public getAllSeats() {
    return this.serviceModel.getAllSeats();
  }

  public getFormattedFreeCancelationUntil() {
    const freeCancelationUntil = this.getFreeCancelationUntil();

    if (!freeCancelationUntil) {
      return null;
    }

    return moment.utc(freeCancelationUntil).format("DD [de] MMMM [de] YYYY");
  }

  public getFormattedItineraryServiceHeaderName() {
    if (this.presentationJourneys) {
      const presentationJourney = this.presentationJourneys[0];

      const isOutboundJourney = presentationJourney.index === 0;
      const isInboundJourney = presentationJourney.index === 1;

      return `Ônibus ${
        isOutboundJourney ? "de ida " : isInboundJourney ? "de volta " : null
      }de ${presentationJourney.departure.city} para ${
        presentationJourney.arrival.city
      }`;
    }

    const outboundJourney = this.getOutboundJourney();

    const arrivalCity = outboundJourney.arrival.city;
    const arrivalState = outboundJourney.arrival.state;

    return `Ônibus ${
      this.serviceModel.isOneWay() ? "ida" : "ida e volta"
    } para ${arrivalCity}, ${arrivalState}`;
  }

  public getFormattedJourneyName(journey: ItineraryBusJourney) {
    return `${journey.departure.city} a ${journey.arrival.city}, partindo às ${journey.departure.time} (${journey.departure.date})`;
  }

  public getFormattedName() {
    return `${this.serviceModel.getFirstJourney().departure.city} - ${
      this.serviceModel.getFirstJourney().arrival.city
    } (${this.serviceModel.isOneWay() ? "Só ida" : "Ida e volta"})`;
  }

  public getIcon(): Icons {
    return "bus";
  }

  public getOutboundJourney() {
    return this.serviceModel.getOutboundJourney();
  }

  public getPresentationJourneys() {
    const presentationJourneys = this.presentationJourneys;

    if (!presentationJourneys) {
      return this.getAllJourneys();
    }

    return presentationJourneys;
  }

  public getService() {
    return this.serviceModel.getService();
  }

  public isOneWay() {
    return this.serviceModel.isOneWay();
  }

  public isSameDayReturn() {
    return this.serviceModel.isSameDayReturn();
  }
}

export class CarItineraryServicePresenter extends ItineraryServicePresenter {
  constructor(protected serviceModel: CarItineraryServiceModel) {
    super(serviceModel);
  }

  public getAllSelectedAdditionals() {
    return this.serviceModel.getAllSelectedAdditionals();
  }

  public getClass() {
    return this.serviceModel.getClass();
  }

  public getContractFeatures() {
    return this.serviceModel.getContractFeatures();
  }

  public getDays() {
    return this.serviceModel.getDays();
  }

  public getDropOff() {
    return this.serviceModel.getDropOff();
  }

  public getFormattedFreeCancelationUntil() {
    const freeCancelationUntil = this.getFreeCancelationUntil();

    if (!freeCancelationUntil) {
      return null;
    }

    const { startDate } = this.getPeriod();

    if (freeCancelationUntil === "any") {
      return moment(startDate).format("DD [de] MMMM [de] YYYY");
    }

    return moment.utc(freeCancelationUntil).format("DD [de] MMMM [de] YYYY");
  }

  public getFormattedItineraryServiceHeaderName() {
    const { storeName } = this.getDropOff();
    const { endDate, startDate } = this.getPeriod();

    return `${differenceInDays(
      startDate,
      endDate,
    )} diária(s) partindo de ${storeName}`;
  }

  public getFormattedName() {
    return `Veículo da ${this.getRentalCompany().name} em ${
      this.getPickUp().address
    }`;
  }

  public getIcon(): Icons {
    return "car";
  }

  public getModel() {
    return this.serviceModel.getModel();
  }

  public getModelFeatures() {
    return this.serviceModel.getModelFeatures();
  }

  public getPickUp() {
    return this.serviceModel.getPickUp();
  }

  public getRentalCompany() {
    return this.serviceModel.getRentalCompany();
  }

  public getSelectedAccessoryAdditionals() {
    return this.serviceModel.getSelectedAccessoryAdditionals();
  }

  public getSelectedProtectionAdditionals() {
    return this.serviceModel.getSelectedProtectionAdditionals();
  }

  public getSelectedServiceAdditionals() {
    return this.serviceModel.getSelectedServiceAdditionals();
  }

  public getService() {
    return this.serviceModel.getService();
  }

  public getSupplier() {
    return this.serviceModel.getSupplier();
  }

  public hasDiffDropOff() {
    return this.serviceModel.hasDiffDropOff();
  }
}

export class FlightItineraryServicePresenter extends ItineraryServicePresenter {
  public presentationJourneys: ItineraryFlightJourney[] | undefined;

  constructor(protected serviceModel: FlightItineraryServiceModel) {
    super(serviceModel);
  }

  public getAdvance() {
    return this.serviceModel.getAdvance();
  }

  public getAllJourneys() {
    return this.serviceModel.getAllJourneys();
  }

  public getCommomBaggagePieces() {
    return this.serviceModel.getCommomBaggagePieces();
  }

  public getCommomCabinClass() {
    return this.serviceModel.getCommomCabinClass();
  }

  public getCommomFareFamily() {
    return this.serviceModel.getCommomFareFamily();
  }

  public getFirstJourney() {
    return this.serviceModel.getFirstJourney();
  }

  public getFormattedFreeCancelationUntil() {
    const freeCancelationUntil = this.getFreeCancelationUntil();

    if (!freeCancelationUntil) {
      return null;
    }

    return moment.utc(freeCancelationUntil).format("DD [de] MMMM [de] YYYY");
  }

  public getFormattedItineraryServiceHeaderName() {
    if (this.presentationJourneys) {
      const presentationJourney = this.presentationJourneys[0];

      const isOutboundJourney = presentationJourney.index === 0;
      const isInboundJourney = presentationJourney.index === 1;

      return `Voo ${
        isOutboundJourney ? "de ida " : isInboundJourney ? "de volta " : null
      }partindo de ${presentationJourney.departure.airportCode} para ${
        presentationJourney.arrival.airportCode
      }`;
    }

    const outboundJourney = this.getOutboundJourney();

    const arrivalAirport = outboundJourney.arrival.airportCode;
    const departureAirport = outboundJourney.departure.airportCode;

    const search = this.getSearch();

    const arrivalCity = search.destinationCity;
    const departureCity = search.originCity;

    return `Voo de ${this.isOneWay() ? "ida" : "ida e volta"} partindo de ${
      departureCity ? departureCity : departureAirport
    } para ${arrivalCity ? arrivalCity : arrivalAirport}`;
  }

  public getFormattedJourneyName(journey: ItineraryFlightJourney) {
    return `${journey.departure.airportName} (${journey.departure.airportCode}) - ${journey.arrival.airportName} (${journey.arrival.airportCode})`;
  }

  public getFormattedName() {
    const { arrival, departure } = this.getFirstJourney();

    return `${departure.airportName} (${departure.airportCode}) - ${
      arrival.airportName
    } (${arrival.airportCode}) (${this.isOneWay() ? "Só ida" : "Ida e volta"})`;
  }

  public getIcon(): Icons {
    return "airplane-taking-off";
  }

  public getJourneyDaysDiff(journey: {
    arrival: { date: string; time: string };
    departure: { date: string; time: string };
  }) {
    return this.serviceModel.getJourneyDaysDiff(journey);
  }

  public getInboundJourney() {
    return this.serviceModel.getInboundJourney();
  }

  public getOutboundJourney() {
    return this.serviceModel.getOutboundJourney();
  }

  public getPresentationJourneys() {
    const presentationJourneys = this.presentationJourneys;

    if (!presentationJourneys) {
      return this.getAllJourneys();
    }

    return presentationJourneys;
  }

  public getPriceExcess() {
    return this.serviceModel.getPriceExcess();
  }

  public getReservationStatus() {
    return this.serviceModel.getReservationStatus();
  }

  public getReservationReservedUntil() {
    return this.serviceModel.getReservationReservedUntil();
  }

  public getService() {
    return this.serviceModel.getService();
  }

  public isInternational() {
    return this.serviceModel.isInternational();
  }

  public isOneWay() {
    return this.serviceModel.isOneWay();
  }

  public isReservationUnitiated() {
    return this.serviceModel.isReservationUnitiated();
  }

  public isSameDayReturn() {
    return this.serviceModel.isSameDayReturn();
  }
}

export class HotelItineraryServicePresenter extends ItineraryServicePresenter {
  constructor(protected serviceModel: HotelItineraryServiceModel) {
    super(serviceModel);
  }

  public getAddress() {
    return this.serviceModel.getAddress();
  }

  public getAdvance() {
    return this.serviceModel.getAdvance();
  }

  public getAmenities() {
    return this.serviceModel.getAmenities();
  }

  public getCheckIn() {
    return moment(this.serviceModel.getCheckIn());
  }

  public getCheckInHour() {
    return this.serviceModel.getCheckInHour();
  }

  public getCheckOut() {
    return moment(this.serviceModel.getCheckOut());
  }

  public getCheckOutHour() {
    return this.serviceModel.getCheckOutHour();
  }

  public getCity() {
    return this.serviceModel.getCity();
  }

  public getFormattedCheckIn() {
    const checkInHour = this.getCheckInHour();

    return `${moment(this.getCheckIn()).format("ddd, DD MMM YYYY")}${
      checkInHour ? ` às ${checkInHour}` : ""
    }`;
  }

  public getFormattedCheckOut() {
    const checkOutHour = this.getCheckOutHour();

    return `${this.getCheckOut().format("ddd, DD MMM YYYY")}${
      checkOutHour ? ` às ${checkOutHour}` : ""
    }`;
  }

  public getFormattedFreeCancelationUntil() {
    const freeCancelationUntil = this.getFreeCancelationUntil();

    if (!freeCancelationUntil) {
      return null;
    }

    const { startDate } = this.getPeriod();

    if (freeCancelationUntil === "any") {
      return moment(startDate).format("DD [de] MMMM [de] YYYY");
    }

    return moment.utc(freeCancelationUntil).format("DD [de] MMMM [de] YYYY");
  }

  public getFormattedItineraryServiceHeaderName() {
    const { endDate, startDate } = this.getPeriod();

    return `${differenceInDays(
      startDate,
      endDate,
    )} noite(s) em ${this.getCity()}`;
  }

  public getFormattedName() {
    return `${this.getHotelName()} em ${this.getCity()}`;
  }

  public getHotelName() {
    return this.serviceModel.getHotelName();
  }

  public getIcon(): Icons {
    return "building";
  }

  public getMainImageURL() {
    return this.serviceModel.getMainImageURL();
  }

  public getNightlyPrice() {
    return this.serviceModel.getNightlyPrice();
  }

  public getNights() {
    return this.serviceModel.getNights();
  }

  public getRoom() {
    return this.serviceModel.getRoom();
  }

  public getService() {
    return this.serviceModel.getService();
  }

  public getStars() {
    return this.serviceModel.getStars();
  }

  public getTotalGuests() {
    return this.serviceModel.getTotalGuests();
  }

  public isCompanyNegotiated() {
    return this.serviceModel.isCompanyNegotiated();
  }
}

export class ItineraryServicePresenterFactory {
  static create(
    serviceModel: ItineraryServiceModel | Readonly<ItineraryServiceModel>,
  ) {
    if (serviceModel.isBusService()) {
      return new BusItineraryServicePresenter(serviceModel);
    }

    if (serviceModel.isCarService()) {
      return new CarItineraryServicePresenter(serviceModel);
    }

    if (serviceModel.isFlightService()) {
      return new FlightItineraryServicePresenter(serviceModel);
    }

    if (serviceModel.isHotelService()) {
      return new HotelItineraryServicePresenter(serviceModel);
    }

    throw new Error("invalid service type");
  }

  static createBus(serviceModel: BusItineraryServiceModel) {
    return new BusItineraryServicePresenter(serviceModel);
  }

  static createCar(serviceModel: CarItineraryServiceModel) {
    return new CarItineraryServicePresenter(serviceModel);
  }

  static createFlight(serviceModel: FlightItineraryServiceModel) {
    return new FlightItineraryServicePresenter(serviceModel);
  }

  static createHotel(serviceModel: HotelItineraryServiceModel) {
    return new HotelItineraryServicePresenter(serviceModel);
  }
}

export type ItineraryServicesGroupedByOfferStatus = ReturnType<
  typeof getPresentationServicesGroupedByOfferStatus
>;

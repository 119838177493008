import * as userParser from "~/apps/corporate/parsers/user.parser";
import errorTypes, {
  AUTHENTICATION_ERRORS,
  ERROR,
} from "~/apps/shared/constants/errors";
import { api } from "~/apps/shared/services/api";
import { encryptPassword } from "~/apps/shared/utils/encrypt-password";
import { AxiosError } from "axios";

import {
  CreateUserRequest,
  CreateUserResponse,
  EditUserProfileRequestBody,
  EditUserProfileResponse,
  EditUserRequest,
  EditUserResponse,
  GetShareCodeResponse,
  GetTravelsCountResponse,
  GetUserAcceptedTermsResponse,
  GetUserResponse,
  GetUsersByNameResponse,
  LoginResponse,
  ReenviteUserResponse,
} from "../dtos/user.dto";
import { getAuthorizationHeader } from "../helpers/user.helper";
import * as encryptionApi from "./encryption.api";

export async function addCountInShareCodeInfo(userToken: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/users/${userToken}/add-count-in-share-code-info`,
    })
    .then(({ data }) => data);
}

const catchError = (error: AxiosError) => {
  if (error.response) {
    const { data, status } = error.response;

    const errorData = data?.data;

    if (status === 403) {
      if (data.message === errorTypes.ERROR_INVALID_CREDENTIALS) {
        throw { description: "Usuário ou senha inválidos", title: "title" };
      }

      if (data.message === AUTHENTICATION_ERRORS.AUTH_METHOD_NOT_ENABLED) {
        throw {
          description:
            "Cliente não possuí esse metódo de autenticação habilitado",
          title: "Erro de autenticação",
        };
      }

      if (data.type === errorTypes.EMAIL_ALREADY_EXIST) {
        throw {
          description: "Já existe um usuário cadastrado com este email",
          title: "title",
        };
      }

      if (!!errorData && "login_attempts_left" in errorData) {
        if (errorData.login_attempts_left > 0) {
          throw {
            description: `Email e/ou senha inválidos. Você tem mais ${errorData.login_attempts_left} tentativas de login antes de bloquear sua conta.`,
            title: "title",
          };
        }

        throw {
          description:
            "Sua conta foi bloqueada por excesso de tentativas erradas de login. Altere sua senha para desbloquear a conta.",
          title: "title",
        };
      }
    }

    if (status === 400) {
      if (data.type === errorTypes.ERROR_INVALID_INPUT) {
        throw { description: "Preenchimento inválido", title: "title" };
      }

      if (data.linked_as_approver) {
        throw ERROR.UNEXPECTED_DELETE_LINKED_AS_APPROVER_ERROR;
      }

      if (data.linked_as_approval_target) {
        throw ERROR.UNEXPECTED_DELETE_LINKED_AS_APPROVAL_TARGET_ERROR;
      }

      if (data.linked_as_expense_approval_target) {
        throw {
          description:
            "O usuário está vinculado a um ou mais Processos de Aprovação de Despesas. Remova-o como alvo antes de deletar",
          title: "title",
        };
      }
    }

    throw {
      description: "Usuário ou senha inválidos",
      title: "title",
    };
  }

  throw ERROR.UNEXPECTED;
};

export async function createUser(data: CreateUserRequest) {
  return await api
    .request<CreateUserResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/users",
    })
    .then(({ data }) => userParser.parseCreateUser(data));
}

export async function deleteUser(userToken: string) {
  return api
    .request({
      headers: getAuthorizationHeader(),
      method: "DELETE",
      url: `/booking/user/${userToken}`,
    })
    .then(({ data }) => data)
    .catch(catchError);
}

export async function editUser(data: EditUserRequest, userToken: string) {
  return await api
    .request<EditUserResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/users/${userToken}`,
    })
    .then(({ data }) => userParser.parseEditUser(data));
}

export async function editUserProfile(
  data: EditUserProfileRequestBody,
  userToken: string,
) {
  return await api
    .request<EditUserProfileResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/user/${userToken}/profile`,
    })
    .then(({ data }) => userParser.parseEditUserProfile(data));
}

export async function generateShareCode(userToken: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/users/${userToken}/share-code`,
    })
    .then(({ data }) => data);
}

export async function getShareCode(userToken: string) {
  return await api
    .request<GetShareCodeResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/users/${userToken}/share-code`,
    })
    .then(({ data }) => data);
}

export async function getTravelsCount(loggedUserToken: string, filters: any) {
  const { travelerToken, search, pendingFilters } = filters;

  const parsedPendingFilters = pendingFilters.join("&");

  const params = {
    search,
    traveler: travelerToken,
  };

  return await api
    .request<GetTravelsCountResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      params,
      url: `/booking/users/${loggedUserToken}/travels-count?${parsedPendingFilters}`,
    })
    .then(({ data }) => data);
}

export async function getUser(userToken: string) {
  return await api
    .request<GetUserResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/user/${userToken}`,
    })
    .then(({ data }) => userParser.parseUser(data));
}

export async function getUserAcceptedTerms(userToken: string) {
  return await api
    .request<GetUserAcceptedTermsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/user/${userToken}/terms`,
    })
    .then(({ data }) => data);
}

export async function getUsersByName(name: string) {
  return await api
    .request<GetUsersByNameResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/user/search/${name}`,
    })
    .then(({ data }) => userParser.parseUserByName(data))
    .catch(catchError);
}

export async function login(email: string, password: string) {
  return await encryptionApi
    .getGeneralEncryptionData()
    .then(async ({ encryptionToken, publicKey }) => {
      return await api
        .request<LoginResponse>({
          data: {
            email,
            password: encryptPassword(password, encryptionToken, publicKey),
          },
          method: "POST",
          url: "/auth/basic",
          withCredentials: true,
        })
        .then(({ data }) => userParser.parseLogin(data));
    })
    .catch(catchError);
}

export async function logout() {
  return await api.request<void>({
    headers: getAuthorizationHeader(),
    method: "POST",
    url: "/auth/logout",
  });
}

export async function reenviteUser(userToken: string) {
  return await api
    .request<ReenviteUserResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/user/${userToken}/reinvite`,
    })
    .then(({ data }) => data);
}

export async function ssoLogin(email: string) {
  return await api
    .request<any>({
      data: { email },
      method: "POST",
      url: "/auth/oidc",
      withCredentials: true,
    })
    .then(({ data }) => data.data);
}

export async function ssoLoginUniq(ssoConfigToken: string) {
  return await api
    .request<any>({
      method: "GET",
      url: `/auth/oidc/${ssoConfigToken}/uniq`,
    })
    .then(({ data }) => data.data);
}

export async function trackContextOfChoiceDialogView(userToken: string) {
  return await api
    .request<void>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/users/${userToken}/context-of-choice`,
    })
    .then(({ data }) => data);
}

export async function unarchiveUser(userToken: string) {
  return await api
    .request<void>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/user/${userToken}/unarchive`,
    })
    .then(({ data }) => data);
}

export async function updateUserAcceptTerms(userToken: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/user/${userToken}/terms`,
    })
    .then(({ data }) => data);
}

import * as yup from "yup";

export const editPaymentMethodSchema = yup
  .object()
  .shape({
    allowApprovers: yup.boolean().nullable(true),
    description: yup.string().trim(),
    enableToBus: yup.boolean(),
    enableToCar: yup.boolean(),
    enableToClient: yup.boolean(),
    enableToFlight: yup.boolean(),
    enableToHotel: yup.boolean(),
    enableToOther: yup.boolean(),
    enableToRide: yup.boolean(),
  })
  .test(
    "at-least-one-service-enabled",
    "Marque ao menos uma opção de serviço para o cartão.",
    (schema) => {
      const {
        enableToBus,
        enableToCar,
        enableToFlight,
        enableToHotel,
        enableToOther,
        enableToRide,
      } = schema;

      return !!(
        enableToBus ||
        enableToCar ||
        enableToFlight ||
        enableToHotel ||
        enableToOther ||
        enableToRide
      );
    },
  );

export type EditPaymentMethodSchema = yup.InferType<
  typeof editPaymentMethodSchema
>;

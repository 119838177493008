import { QueryClient, useQuery } from "react-query";

import * as billingProfileApi from "~/apps/corporate/apis/billing-profile.api";
import { ItineraryInfoModel } from "~/apps/corporate/models/itinerary/itinerary-info.model";

export const invalidateBillingProfilePayableCardsForTravel = async (
  queryClient: QueryClient,
) => {
  await queryClient.invalidateQueries({
    predicate: (query) => {
      const { queryKey } = query;

      return (
        queryKey.includes("billing-profiles") && queryKey.includes("cards")
      );
    },
  });
};

export const usePaymentPayableCards = (info: ItineraryInfoModel | null) => {
  const billingProfileToken = info?.getBillingProfileToken();
  const travelToken = info?.getTravelToken();

  const {
    data: payableCards,
    error: errorPayableCards,
    isLoading: isLoadingPayableCards,
  } = useQuery(
    ["billing-profiles", billingProfileToken, travelToken, "cards"],
    async () => {
      if (!billingProfileToken || !travelToken) {
        return;
      }

      return await billingProfileApi.getBillingProfilePayableCardsForTravel(
        billingProfileToken,
        travelToken,
      );
    },
    {
      enabled: !!billingProfileToken && !!travelToken,
    },
  );

  return {
    errorPayableCards,
    isLoadingPayableCards,
    payableCards,
  };
};

export const usePaymentPaymentOptions = (info: ItineraryInfoModel | null) => {
  const billingProfileToken = info?.getBillingProfileToken();
  const travelToken = info?.getTravelToken();

  const {
    data: paymentOptions,
    error: errorPaymentOptions,
    isLoading: isLoadingPaymentOptions,
  } = useQuery(
    ["billing-profiles", billingProfileToken, travelToken, "payment-options"],
    async () => {
      if (!billingProfileToken || !travelToken) {
        return null;
      }

      return await billingProfileApi.getBillingProfilePaymentOptionsForTravel(
        billingProfileToken,
        travelToken,
      );
    },
    {
      enabled: !!billingProfileToken && !!travelToken,
    },
  );

  return {
    errorPaymentOptions,
    isLoadingPaymentOptions,
    paymentOptions: paymentOptions ? paymentOptions : null,
  };
};

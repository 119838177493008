import { api } from "~/apps/shared/services/api";

import {
  GetUserRecentHotelsResponse,
  LoadHotelsResponse,
} from "../dtos/hotel.dto";
import { buildHotelData } from "../helpers/hotel.helper";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import * as hotelParser from "../parsers/hotel.parser";
import { HotelFactory } from "../parsers/travel.parser";

export async function addHotelOffer(data: any, travelToken: string) {
  return await api.request<any>({
    data,
    headers: getAuthorizationHeader(),
    method: "POST",
    url: `/booking/travels/${travelToken}/offers/hotel`,
  });
}

export async function createHotelsSearch(data: any) {
  return await api
    .request<any>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/hotel`,
    })
    .then(({ data }) => HotelFactory(data));
}

async function createNewHotelsInBatch(hotelData: string) {
  const formattedData = buildHotelData(hotelData);

  const hotels = await Promise.all(
    formattedData.map((data: any) => createHotelsSearch(data)),
  );

  const tokens = hotels.map((hotel: any) => ({
    hotel_token: hotel.token,
  }));

  return tokens;
}

export async function getHotelAutossugest(search: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      timeout: 25000,
      url: `/booking/autosuggest/hotel/${search}`,
    })
    .then(({ data }) => {
      return data.location_list.map((location: any) => ({
        ...location,
        label: location.name,
      }));
    });
}

export async function getNearbyPlaces(data: any) {
  return await api
    .request<any>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/hotel/nearby-places",
    })
    .then(({ data }) => data);
}

export async function getReceptionHours(hospitalityHotelToken: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/hotel/hospitality/${hospitalityHotelToken}/reception-hours`,
    })
    .then(({ data }) => data);
}

export async function getUserRecentHotels(userToken: string) {
  return await api
    .request<GetUserRecentHotelsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/users/${userToken}/hotels/recent`,
    })
    .then(({ data }) => hotelParser.parseUserRecentHotels(data));
}

async function loadHotels(hotelToken: string) {
  return await api
    .request<LoadHotelsResponse>({
      url: `/booking/hotel/${hotelToken}/list`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then(({ data }) => data);
}

async function loadHotelDetails(hotelToken: string, hospitalityToken: string) {
  return await api
    .request<any>({
      url: `/booking/hotel/${hotelToken}/hospitality/${hospitalityToken}/details`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then(({ data }) => data);
}

async function loadRooms(hotelToken: string, hospitalityToken: string) {
  return await api
    .request<any>({
      url: `/booking/hotel/${hotelToken}/hospitality/${hospitalityToken}/rooms`,
      method: "GET",
      headers: getAuthorizationHeader(),
    })
    .then(({ data }) => data);
}

export async function removeHotelFavorite(favouriteToken: string) {
  return await api
    .request<any>({
      url: `/booking/clients/${getClientToken()}/hotels/favourites/${favouriteToken}`,
      method: "DELETE",
      headers: getAuthorizationHeader(),
    })
    .then(({ data }) => data);
}

export async function setHotelFavorite(requestData: any) {
  return await api
    .request<any>({
      url: `/booking/clients/${getClientToken()}/hotels/favourites`,
      method: "POST",
      headers: getAuthorizationHeader(),
      data: requestData,
    })
    .then(({ data }) => data);
}

export const hotelApi = {
  addHotelOffer,
  createNewHotel: createHotelsSearch,
  createNewHotelsInBatch,
  getNearbyPlaces,
  getReceptionHours,
  getUserRecentHotels,
  loadHotelDetails,
  loadHotels,
  loadRooms,
  removeHotelFavorite,
  setHotelFavorite,
};

export default hotelApi;

import * as clientApi from "~/apps/corporate/apis/client.api";
import * as travelApi from "~/apps/corporate/apis/travel.api";
import * as userApi from "~/apps/corporate/apis/user.api";
import { TripsStatusFilter } from "~/apps/shared/constants/enums";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";
import { formatServiceError } from "~/apps/shared/utils/errors";

export const checkTravelCopyStatus = async (copyTravelToken: string) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await travelApi.getTravelCopyStatus(copyTravelToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CHECK_TRAVEL_COPY_STATUS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CHECK_TRAVEL_COPY_STATUS_ERROR;
    }
  }

  return result;
};

export const deleteTravel = async (travelToken: string) => {
  const result: { error?: Error } = {};

  try {
    await travelApi.deleteTravel(travelToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_TRAVEL_LIST_DELETE_TRAVEL_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_TRAVEL_LIST_DELETE_TRAVEL_ERROR;
    }
  }

  return result;
};

export const getClientUsers = async () => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await clientApi.getUsers();
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_TRAVELS_USERS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_TRAVELS_USERS_ERROR;
    }
  }

  return result;
};

export const getTravelsCount = async (
  loggedUserToken: string,
  filters: {
    pendingFilters: TripsStatusFilter[];
    search: string;
    travelerToken: string;
  },
) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await userApi.getTravelsCount(loggedUserToken, filters);
  } catch (error) {
    if (error.response) {
      return { error: ERROR.UNEXPECTED_TRAVELS_COUNT_ERROR };
    } else if (error.request) {
      return { error: ERROR.GENERAL_CONNECTION_ERROR };
    } else {
      return { error: ERROR.UNEXPECTED_TRAVELS_COUNT_ERROR };
    }
  }

  return result;
};

export const getUserTravels = async (query: any) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await travelApi.getUserTravels(query);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_LIST_USER_TRAVELS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_LIST_USER_TRAVELS_ERROR;
    }
  }

  return result;
};

export const loadUserPoints = async (userToken: string) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await travelApi.getLoggedUserPointsInfo(userToken);
  } catch (error) {
    result.error = formatServiceError({
      error,
      fallback: ERROR.UNEXPECTED_LOAD_POINTS_ERROR,
    });
  }

  return result;
};

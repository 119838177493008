import React, { Suspense } from "react";

import { Redirect, RouteComponentProps } from "@reach/router";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { CapabilitiesLiterals } from "~/apps/shared/constants/enums";

import { LoadingOverlay } from "../loading-overlay/loading-overlay";

type Props = RouteComponentProps & {
  atLeastOne?: boolean;
  capabilities?: CapabilitiesLiterals[];
  component: React.ElementType;
};

export const CapabilityProtectedRoute: React.FC<Props> = ({
  atLeastOne = false,
  capabilities = [],
  component: Component,
  ...props
}) => {
  const { isLoggedIn, user } = useUser();

  if (!isLoggedIn) {
    return <Redirect noThrow to="/login" />;
  }

  if (!user) {
    return null;
  }

  if (atLeastOne) {
    if (
      capabilities.length > 0 &&
      capabilities.some((capability) => user.hasCapability(capability))
    ) {
      return (
        <Suspense fallback={<LoadingOverlay open />}>
          <Component {...props} />
        </Suspense>
      );
    }
  }

  if (
    capabilities.length <= 0 ||
    !capabilities.every((capability) => user.hasCapability(capability))
  ) {
    return <Redirect noThrow to="/permission" />;
  }

  return (
    <Suspense fallback={<LoadingOverlay open />}>
      <Component {...props} />
    </Suspense>
  );
};

import { getUserFromAccessToken } from "~/apps/corporate/helpers/user.helper";
import {
  Capabilities,
  UserBookingPhoneConfig,
} from "~/apps/shared/constants/enums";
import { addBrazilPhonePrefix } from "~/apps/shared/utils/add-brazil-phone-prefix";
import { formatPhone } from "~/apps/shared/utils/format-phone";
import _ from "lodash";

import { GetUsersResponseClientUserDto } from "../dtos/client.dto";
import {
  CreateUserRequest,
  CreateUserResponse,
  EditUserProfileResponse,
  EditUserRequest,
  EditUserResponse,
  GetUserResponse,
  GetUsersByNameResponse,
  LoginResponse,
} from "../dtos/user.dto";
import { User, UserModelFactory } from "../models/user.model";
import {
  CreateUserSchema,
  EditUserSchema,
} from "../pages/configurations/views/users/users/users-user-form/users-user-form.schema";

export const parseClientUser = (dto: GetUsersResponseClientUserDto) => {
  const user: User = {
    active: dto.active,
    agencyToken: dto.agency_token,
    billingProfileToken: dto.billing_profile_token,
    bookingPhoneConfig: parseUserBookingPhoneConfig(dto.booking_phone_config),
    clientToken: dto.client_token,
    createdAt: dto.created_at,
    email: dto.email,
    firstName: dto.first_name,
    fullName: dto.full_name,
    guest: dto.guest,
    lastLogin: dto.last_login,
    lastName: dto.last_name,
    phone: dto.phone ? dto.phone : null,
    role: dto.role,
    sendSms: dto.send_sms,
    userToken: dto.user_token,
  };

  return user;
};

export const parseCreateUser = (dto: CreateUserResponse) => {
  const user: User = {
    active: dto.active,
    bookingPhoneConfig: parseUserBookingPhoneConfig(dto.booking_phone_config),
    capabilities: dto.capabilities,
    email: dto.email,
    firstName: dto.first_name,
    fullName: dto.full_name,
    guest: dto.guest,
    hidden: dto.hidden,
    lastName: dto.last_name,
    phone: dto.phone,
    role: dto.role,
    sendSms: dto.send_sms,
    userToken: dto.user_token,
    userPreference: dto.userPreference,
  };

  return user;
};

export const parseCreateUserRequest = (dto: CreateUserSchema) => {
  const formattedBillingProfileToken = dto.billingProfileToken
    ? dto.billingProfileToken && dto.billingProfileToken !== "null"
      ? dto.billingProfileToken
      : null
    : undefined;

  const formattedBookingTargetTokens =
    dto.capabilities.search && dto.bookerTargets
      ? (dto.bookerTargets
          .filter((bookerTarget) => !!bookerTarget.userToken)
          .map((bookerTarget) => bookerTarget.userToken) as string[])
      : [];

  const formattedCapabilities = _.omit(dto.capabilities, [
    "manualManagement",
  ]) as Capabilities;

  const formattedPhone = dto.phone
    ? addBrazilPhonePrefix(formatPhone(dto.phone))
    : undefined;

  const createUserRequest: CreateUserRequest = {
    ...(formattedBillingProfileToken && {
      billing_profile_token: formattedBillingProfileToken,
    }),
    booking_target_tokens: formattedBookingTargetTokens,
    capabilities: formattedCapabilities,
    client_token: dto.clientToken,
    email: dto.email.trim(),
    first_name: dto.firstName.trim(),
    guest: dto.guest,
    last_name: dto.lastName.trim(),
    ...(formattedPhone && { phone: formattedPhone }),
    role: dto.role,
    send_invite: dto.sendInvite,
  };

  return createUserRequest;
};

export const parseEditUser = (dto: EditUserResponse) => {
  const user: User = {
    active: dto.active,
    agencyToken: dto.agency_token,
    billingProfileToken: dto.billing_profile_token,
    bookingPhoneConfig: parseUserBookingPhoneConfig(dto.booking_phone_config),
    capabilities: dto.capabilities,
    clientToken: dto.client_token,
    createdAt: dto.created_at,
    email: dto.email,
    firstName: dto.first_name,
    fullName: dto.full_name,
    guest: dto.guest,
    hasAcceptedTerms: dto.accepted_terms,
    hidden: dto.hidden,
    lastLogin: dto.last_login,
    lastName: dto.last_name,
    phone: dto.phone,
    role: dto.role,
    sendSms: dto.send_sms,
    userToken: dto.user_token,
  };

  return user;
};

export const parseEditUserProfile = (dto: EditUserProfileResponse) => {
  const user: User = {
    active: dto.active,
    agencyToken: dto.agency_token,
    billingProfileToken: dto.billing_profile_token,
    bookingPhoneConfig: parseUserBookingPhoneConfig(dto.booking_phone_config),
    capabilities: dto.capabilities,
    email: dto.email,
    firstName: dto.first_name,
    fullName: dto.full_name,
    guest: dto.guest,
    lastName: dto.last_name,
    phone: dto.phone,
    role: dto.role,
    sendSms: dto.send_sms,
    userToken: dto.user_token,
  };

  return user;
};

export const parseEditUserRequest = (dto: EditUserSchema | User) => {
  const formattedBillingProfile = dto.billingProfileToken
    ? dto.billingProfileToken === "null"
      ? undefined
      : dto.billingProfileToken
    : undefined;

  const formattedBookingTargetTokens =
    dto.capabilities && dto.capabilities.search && "bookerTargets" in dto
      ? ((dto as EditUserSchema)
          .bookerTargets!.filter((bookerTarget) => !!bookerTarget.userToken)
          .map((bookerTarget) => bookerTarget.userToken) as string[])
      : undefined;

  const formattedCapabilities = dto.capabilities
    ? (_.omit(dto.capabilities, ["manualManagement"]) as Capabilities)
    : undefined;

  const formattedGuest =
    dto.guest === false || dto.guest === true ? dto.guest : undefined;

  const formattedPhone = dto.phone
    ? addBrazilPhonePrefix(formatPhone(dto.phone))
    : undefined;

  const editUserRequest: EditUserRequest = {
    ...(formattedBillingProfile && {
      billing_profile_token: formattedBillingProfile,
    }),
    ...(formattedBookingTargetTokens && {
      booking_target_tokens: formattedBookingTargetTokens,
    }),
    ...(dto.bossToken && { boss_token: dto.bossToken }),
    ...(formattedCapabilities && { capabilities: formattedCapabilities }),
    ...(dto.email && { email: dto.email.trim() }),
    ...(dto.firstName && { first_name: dto.firstName.trim() }),
    ...(formattedGuest && { guest: formattedGuest }),
    ...(dto.lastName && { last_name: dto.lastName.trim() }),
    ...(formattedPhone && { phone: formattedPhone }),
    ...(dto.role && { role: dto.role }),
    ...(dto.sendSms && { send_sms: dto.sendSms }),
  };

  return editUserRequest;
};

export const parseLogin = (dto: LoginResponse) => {
  const { access_token } = dto.data;

  localStorage.setItem("access_token", access_token);

  return getUserFromAccessToken(access_token);
};

export const parseUser = (dto: GetUserResponse) => {
  const user: User = {
    active: dto.active,
    agencyToken: dto.agency_token,
    billingProfileToken: dto.billing_profile_token,
    bookingPhoneConfig: parseUserBookingPhoneConfig(dto.booking_phone_config),
    capabilities: dto.capabilities,
    clientToken: dto.client_token,
    createdAt: dto.created_at,
    email: dto.email,
    firstName: dto.first_name,
    fullName: dto.full_name
      ? dto.full_name
      : `${dto.first_name} ${dto.last_name}`,
    guest: !!dto.guest,
    hidden: !!dto.hidden,
    lastLogin: dto.last_login,
    lastName: dto.last_name,
    phone: dto.phone,
    role: dto.role,
    sendSms: !!dto.send_sms,
    userToken: dto.user_token,
    vipStatus: dto.vip_status,
  };

  const userModel = UserModelFactory.create(user);

  return userModel;
};

export const parseUserByName = (dtos: GetUsersByNameResponse) => {
  return dtos.map((dto) => {
    const user: User = {
      billingProfileToken: dto.billing_profile_token,
      clientToken: dto.client_token,
      email: dto.email,
      firstName: dto.first_name,
      fullName: dto.full_name,
      lastName: dto.last_name,
      userToken: dto.user_token,
    };

    return user;
  });
};

export const parseUserBookingPhoneConfig = (bookingPhoneConfig?: string) => {
  if (!bookingPhoneConfig) {
    return UserBookingPhoneConfig.UNITIATED;
  }

  const userBookingPhoneConfig = bookingPhoneConfig as UserBookingPhoneConfig;

  if (!Object.values(UserBookingPhoneConfig).includes(userBookingPhoneConfig)) {
    throw new Error(
      `bookingPhoneConfig ${bookingPhoneConfig} is not supported.`,
    );
  }

  return userBookingPhoneConfig;
};

export const UserAutocompleteFactory = (
  dto: Partial<
    Record<
      | "client_token"
      | "document"
      | "email"
      | "first_name"
      | "full_name"
      | "last_name"
      | "user_token",
      any
    >
  >,
) => {
  const {
    client_token,
    document,
    email,
    first_name,
    full_name,
    last_name,
    user_token,
  } = dto;

  const result: Record<
    | "clientToken"
    | "document"
    | "email"
    | "firstName"
    | "fullName"
    | "label"
    | "lastName"
    | "userToken",
    any
  > = {
    clientToken: client_token,
    document: document,
    email: email,
    firstName: first_name,
    fullName: full_name,
    label: full_name,
    lastName: last_name,
    userToken: user_token,
  };

  return result;
};

export const UserArea = (
  dto: Partial<
    Record<
      | "email"
      | "first_name"
      | "full_name"
      | "last_name"
      | "user_token"
      | "userArea",
      any
    >
  >,
) => {
  const { email, first_name, full_name, last_name, user_token, userArea } = dto;

  const result: Record<
    | "email"
    | "firstName"
    | "fullName"
    | "lastName"
    | "userAreaToken"
    | "userToken",
    any
  > = {
    email: email,
    firstName: first_name,
    fullName: full_name,
    lastName: last_name,
    userAreaToken: userArea.user_area_token,
    userToken: user_token,
  };

  return result;
};

export const UserFactory = (
  dto: Partial<
    Record<
      | "active"
      | "billing_profile_token"
      | "booking_phone_config"
      | "capabilities"
      | "client_token"
      | "created_at"
      | "document"
      | "email"
      | "first_name"
      | "full_name"
      | "guest"
      | "has_accepted_terms"
      | "hidden"
      | "last_login"
      | "last_name"
      | "phone"
      | "role"
      | "send_sms"
      | "user_token",
      any
    >
  >,
) => {
  const {
    active,
    billing_profile_token,
    booking_phone_config,
    capabilities,
    client_token,
    created_at,
    document,
    email,
    first_name,
    full_name,
    guest,
    has_accepted_terms,
    hidden,
    last_login,
    last_name,
    phone,
    role,
    send_sms,
    user_token,
  } = dto;

  const result: Record<
    | "active"
    | "billingProfileToken"
    | "bookingPhoneConfig"
    | "capabilities"
    | "clientToken"
    | "createdAt"
    | "document"
    | "email"
    | "firstName"
    | "fullName"
    | "guest"
    | "hasAcceptedTerms"
    | "hidden"
    | "lastLogin"
    | "lastName"
    | "phone"
    | "role"
    | "sendSms"
    | "userToken",
    any
  > = {
    active: active,
    billingProfileToken: billing_profile_token,
    bookingPhoneConfig: booking_phone_config
      ? booking_phone_config
      : UserBookingPhoneConfig.UNITIATED,
    capabilities: capabilities,
    clientToken: client_token,
    createdAt: created_at,
    document: document,
    email: email,
    firstName: first_name,
    fullName: full_name,
    guest: guest,
    hasAcceptedTerms: has_accepted_terms,
    hidden: hidden,
    lastLogin: last_login,
    lastName: last_name,
    phone: phone,
    role: role,
    sendSms: send_sms,
    userToken: user_token,
  };

  return result;
};

export const UsersArea = (
  dtos: Partial<
    Record<
      | "email"
      | "first_name"
      | "full_name"
      | "last_name"
      | "user_token"
      | "userArea",
      any
    >
  >[],
) => {
  return dtos.map((dto) => UserArea(dto));
};
